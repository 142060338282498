import type { SubscriptionDetailsResponse } from "@9amhealth/openapi";
import { useMemo } from "react";
import { PayerId } from "src/constants/payers";
import SubscriptionCubit from "src/state/SubscriptionCubit/SubscriptionCubit";
import { SubscriptionInterval } from "src/state/TreatmentPlanCubit/TreatmentPlanCubit";
import { useBloc } from "src/state/state";

const useSubscription = (options: {
  status: SubscriptionDetailsResponse.status[];
  duration?: SubscriptionInterval[];
  metadataPartnerFunnel?: string[];
  metadataPayerId?: PayerId;
}): SubscriptionDetailsResponse | undefined => {
  const {
    status,
    duration = [SubscriptionInterval.monthly, SubscriptionInterval.quarterly],
    metadataPartnerFunnel,
    metadataPayerId
  } = options;

  const [{ allSubscriptions }, { filterAllSubscriptions }] =
    useBloc(SubscriptionCubit);

  const funnelSpecificSubscription = useMemo(() => {
    return filterAllSubscriptions({
      status,
      duration,
      looseCheck: true,
      metadataPartnerFunnel,
      metadataPayerId
    })[0] as SubscriptionDetailsResponse | undefined;
  }, [allSubscriptions]);

  return funnelSpecificSubscription;
};

export default useSubscription;
