import { css } from "@emotion/css";
import styled from "@emotion/styled";
import type { FC } from "react";
import React from "react";
import { useSearchParams } from "react-router-dom";
import InDialog from "src/ui/components/InDialog/InDialog";
import useGoToOrBack from "src/ui/hooks/useGoToOrBack";
import { tracker } from "src/state/state";
import Translate from "../../Translate/Translate";
import { TaskResponse } from "@9amhealth/openapi/generated/models/TaskResponse";
import { Button } from "react-aria-components";
import { IconChevronRight } from "src/constants/icons";
import Loader from "../../Loader/Loader";

const BarInfo = styled.div`
  background: var(--Greys-Light-Gray, #e6e3db);
  border-radius: 0;
  border: none;
  width: 100%;
  padding: 1rem;
  outline: none;
  position: sticky;
  z-index: 100;
  top: 0;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BarButton = styled(Button)`
  background: var(--Primary-criticalRed, #ff7663);
  border-radius: 0;
  padding: 1rem;
  border: none;
  width: 100%;
  outline: none;
  position: sticky;
  z-index: 100;
  top: 0;

  > div::before {
    opacity: 0;
  }

  svg {
    display: inline-block;
    height: 1rem;
    width: auto;
    transform: scale(1.5) translate(0.1rem, 0.1rem);

    path {
      fill: currentColor;
    }
  }
`;

const ionModalStyles = css`
  // popup options for iframe
  ion-content {
    &::part(scroll) {
      background: white;
    }
  }
`;

const DetailFrame = styled.iframe`
  border: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: none;
  transition: opacity 0.5s ease-in-out;
  transition-property: opacity;
  transition-delay: 0.2s;
  margin: 0;
  padding-bottom: 0 !important;
  position: relative;
  z-index: 80;
  scroll-margin-top: 8rem;
`;

const AppointmentBookingDialog: FC<{
  returnUrl: string;
}> = ({ returnUrl }) => {
  const navigate = useGoToOrBack();
  const [modalRef, setModalRef] =
    React.useState<React.RefObject<HTMLIonModalElement> | null>(null);

  const [query] = useSearchParams();
  const title = query.get("title") ?? "";
  const taskStatus: TaskResponse.status | "" = (query.get("status") ??
    "") as TaskResponse.status;

  const onClose = () => {
    void modalRef?.current?.dismiss();
    navigate(returnUrl, {
      multiBack: true,
      replace: true
    });
  };

  const scheduleUrl = query.get("url") ?? "";

  const skipTask = () => {
    tracker.track(`${title} Skip Task`);
    document.dispatchEvent(new CustomEvent("skipped-appointment"));
    onClose();
  };

  return (
    <InDialog
      title={title}
      onClose={onClose}
      setRef={setModalRef}
      returnUrl={returnUrl}
      className={ionModalStyles}
      simple
      width="min(calc(100% - 4rem), 120rem)"
      height="min(calc(100% - 4rem), 70rem)"
    >
      <div style={{ height: "100%" }}>
        {taskStatus === "AVAILABLE" && (
          <BarButton onPress={skipTask}>
            <Translate msg="task.skip" />
            <IconChevronRight />
          </BarButton>
        )}
        {taskStatus === "SKIPPED" && (
          <BarInfo>
            <Translate msg="task.hasBeenSkip" />
          </BarInfo>
        )}
        {scheduleUrl && <DetailFrame src={scheduleUrl} id="app-query-iframe" />}
        {!scheduleUrl && <Loader active fixed />}
      </div>
    </InDialog>
  );
};

export default AppointmentBookingDialog;
