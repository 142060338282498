import React from "react";
const IconEducation = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="#212121"
      d="M20.05 9.119a.277.277 0 0 1 .012-.007l1.43-.713-1.352-.675c0 .856-.536 1.561-1.72 2.209l1.63-.814Z"
    />
    <path
      fill="#212121"
      fillRule="evenodd"
      d="m18.42 9.933-.567.283c.204-.093.393-.188.567-.283Zm1.01 8.258-.02.01.02-.01Zm-6.928-4.532.006-.003.013-.006-.019.01Z"
      clipRule="evenodd"
    />
    <path
      fill="#212121"
      fillRule="evenodd"
      d="m20.555 9.637 1.861-.93a.344.344 0 0 0 0-.616l-9.895-4.943c-.008-.005-.017-.01-.025-.013-.109-.055-.218-.11-.338-.132a.859.859 0 0 0-.316 0c-.12.022-.23.077-.338.132l-.025.013L1.584 8.09a.344.344 0 0 0 0 .616l1.861.93v6.82c0 .328 0 .576.08.8.07.197.185.376.335.522.17.166.396.269.695.405l.05.023 6.567 3.008.016.007.02.009c.217.1.381.175.557.205.156.027.314.027.47 0 .176-.03.34-.106.558-.205l.035-.017 6.567-3.007.015-.007.02-.009.015-.007c.299-.136.524-.24.695-.405a1.37 1.37 0 0 0 .335-.522c.08-.224.08-.472.08-.8V9.637ZM11.969 3.68a.168.168 0 0 1 .062 0c.012.002.03.008.182.084l7.927 3.96 1.351.675-1.429.713a.277.277 0 0 0-.013.007l-1.629.814c-.174.095-.363.19-.567.283l-.737.368a2.04 2.04 0 0 0-.253-.141l-.013-.007a1.118 1.118 0 0 1-.019-.009l-4.677-2.336a.345.345 0 0 0-.308.616l3.257 1.627 1.257.628-4.106 2.051-.041.02c-.153.077-.17.082-.182.084a.17.17 0 0 1-.062 0 1.033 1.033 0 0 1-.182-.084L3.951 9.12a.36.36 0 0 0-.013-.007L2.508 8.4l9.28-4.635c.152-.076.169-.082.181-.084Zm5.507 7.789V11.4c0-.074 0-.149-.009-.222l1.725-.845c0 6.445-6.364 9.453-9.446 9.453l-4.854-2.206c-.37-.17-.477-.224-.55-.296a.68.68 0 0 1-.167-.26c-.035-.096-.04-.215-.04-.622V9.98l7.344 3.669.025.013c.109.054.218.109.338.131.104.02.212.02.316 0 .12-.022.23-.077.338-.131l.006-.004.02-.01 4.264-2.13v2.244a.345.345 0 0 0 .69 0v-2.294Z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconEducation;
