import { getLocalTimeZone, now, ZonedDateTime } from "@internationalized/date";
import React, { FC, useRef } from "react";
import { TimePickerSheet } from "../TimePickerSheet/TimePickerSheet";
import reportErrorSentry from "src/lib/reportErrorSentry";
import ReactDOM from "react-dom";

const DateTimePicker: FC<{
  show: boolean;
  onFinish?: (dateTime: ZonedDateTime) => void;
  onChange?: (dateTime: ZonedDateTime) => void;
  initialDate?: ZonedDateTime;
  title: string;
  granularity: "day" | "minute";
}> = (props) => {
  const {
    show,
    onFinish,
    onChange,
    initialDate = now(getLocalTimeZone()),
    title,
    granularity
  } = props;
  const portalTarget = document.getElementById("date-time-picker-target");
  const selected = useRef<ZonedDateTime>(initialDate);

  const handleFinish = () => {
    onFinish?.(selected.current);
  };

  const handleSelectChange = (dateTime: ZonedDateTime) => {
    selected.current = dateTime;
    onChange?.(dateTime);
  };

  if (!show) {
    return null;
  }

  if (!portalTarget) {
    reportErrorSentry(new Error("DateTimePicker portal target not found"));
    return null;
  }

  return ReactDOM.createPortal(
    <TimePickerSheet
      onFinish={handleFinish}
      onChange={handleSelectChange}
      title={title}
      granularity={granularity}
      initialDate={initialDate}
    />,
    portalTarget
  );
};

export default DateTimePicker;
