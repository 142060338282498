import { Button } from "@9amhealth/shared";
import type { FC } from "react";
import React from "react";
import { IconTranscarent } from "src/constants/icons";
import { APP_CONTENT_WIDTH_WITHOUT_PADDING } from "src/constants/layout";
import Translate from "../Translate/Translate";
import styled from "@emotion/styled";
import { getContinueWithTranscarentTargetUrl } from "src/lib/getContinueWithTranscarentTargetUrl";
import { isHybridApp } from "src/lib/platform";

const Centered = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  max-width: var(--section-max-width);
`;

const ReAuthenticateUsingTranscarent: FC = () => {
  const handleTranscarentButtonClick = () => {
    const target = getContinueWithTranscarentTargetUrl();

    if (!target) {
      return;
    }

    window.open(target, isHybridApp() ? "_blank" : undefined);
  };

  return (
    <Centered>
      <Content
        style={
          {
            "--section-max-width": `${APP_CONTENT_WIDTH_WITHOUT_PADDING}px`
          } as React.CSSProperties
        }
      >
        <nine-heading>
          <h3 className="as-h4-large">
            <Translate msg={"reauthenticate_title"} />
          </h3>
          <nine-spacer s="sm"></nine-spacer>
          <p className="m0 color-c-80">
            <Translate
              msg={"login.subtitle"}
              variables={{ context: "reauth_transcarent" }}
            />
          </p>
        </nine-heading>
        <nine-spacer s="md"></nine-spacer>
        <Button
          center
          theme="charcoal"
          hideArrow={true}
          outline={true}
          icon={<IconTranscarent />}
          onPress={() => handleTranscarentButtonClick()}
        >
          <Translate msg="login" variables={{ context: "transcarent" }} />
        </Button>
      </Content>
    </Centered>
  );
};

export default ReAuthenticateUsingTranscarent;
