import styled from "@emotion/styled";
import type { FC, RefObject } from "react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IconCheckmarkCompleted } from "src/constants/icons";
import createTrackEvent from "src/lib/createTrackEvent";
import { taskManagementState, tracker, userState } from "src/state/state";
import Translate from "src/ui/components/Translate/Translate";
import CustomSuspense from "../../CustomSuspense/CustomSuspense";
import InDialog from "../../InDialog/InDialog";
import translate from "src/lib/translate";
import {
  BEHAVIORAL_HEALTH_QUESTIONNAIRE_GAD7,
  BEHAVIORAL_HEALTH_QUESTIONNAIRE_HOW_DIFFICULT_ANSWER_EXTREMELY,
  BEHAVIORAL_HEALTH_QUESTIONNAIRE_HOW_DIFFICULT_ANSWER_SOMEWHAT,
  BEHAVIORAL_HEALTH_QUESTIONNAIRE_HOW_DIFFICULT_ANSWER_VERY,
  BEHAVIORAL_HEALTH_QUESTIONNAIRE_HOW_DIFFICULT_QUESTION_ID,
  BEHAVIORAL_HEALTH_QUESTIONNAIRE_PHQ8
} from "src/constants/misc";
import { Button } from "@9amhealth/shared";
import {
  CustomQuestionnaireAnswer,
  CustomQuestionnaireChoice
} from "src/state/QuestionnaireCubit/QuestionnaireState";
import { UpdateTaskRequest } from "@9amhealth/openapi";
import { KnownProgram } from "src/state/ProgramBloc/ProgramBloc";

export const CenterQuestionnaireContent = styled.div`
  label: CenterQuestionnaireContent;
  display: grid;
  place-items: center;
  min-height: 100%;
  width: 100%;
  padding: 2rem 1rem 5rem !important;
  margin-bottom: calc(var(--keyboard-height, 50vh), 5rem);

  > div {
    width: 100%;
  }
`;

export const CenterEl = styled.div`
  border-radius: 0.6em;
  width: 90vw;
  max-width: 580px;
  text-align: center;
  margin: 0 auto;

  a {
    color: var(--color-sunrise-blue);
  }

  svg {
    width: 3rem;
    height: auto;
    border: 2px solid var(--color-charcoal-60);
    border-radius: 50%;
  }
`;

const AsyncCustomQuestionnaire = React.lazy(
  async () => import("../../CustomQuestionnaire/CustomQuestionnaire")
);

const InAppMultipleQuestionnairesDialog: FC<{
  returnUrl?: string;
}> = () => {
  const [currentQuestionnaire, setCurrentQuestionnaire] = useState<
    "gad" | "phq" | "completed"
  >("phq");

  const [answers, setAnswers] = useState<{
    phqAnswers: CustomQuestionnaireAnswer[];
    gadAnswers: CustomQuestionnaireAnswer[];
  }>();

  const markTaskAsStarted = () => {
    void taskManagementState.updateTaskStatus(
      {
        program: KnownProgram.BEHAVIORAL_HEALTH_SCREENING,
        group: "amazon",
        slug: "bh-screening-questionnaires"
      },
      UpdateTaskRequest.status.IN_PROGRESS
    );
  };
  const [overlayRef, setOverlayRef] =
    useState<RefObject<HTMLIonModalElement>>();
  const navigate = useNavigate();

  const handleClose = () => {
    if (overlayRef?.current) {
      void overlayRef.current.dismiss();
    }
    navigate("/app");
  };

  useEffect(() => {
    markTaskAsStarted();
    tracker.track(createTrackEvent("Behavioral Health Questionnaire Opened"));
  }, []);

  useEffect(() => {
    const fn = async () => {
      const { phqAnswers, gadAnswers } =
        await userState.getBehavioralHealthQuestionnaireAnswers();
      setAnswers({ phqAnswers, gadAnswers });
    };

    void fn();
  }, [currentQuestionnaire]);

  const reportsSomewhatVeryOrExtremely = (lastQuestionChoiceId?: string) => {
    return (
      lastQuestionChoiceId ===
        BEHAVIORAL_HEALTH_QUESTIONNAIRE_HOW_DIFFICULT_ANSWER_SOMEWHAT ||
      lastQuestionChoiceId ===
        BEHAVIORAL_HEALTH_QUESTIONNAIRE_HOW_DIFFICULT_ANSWER_VERY ||
      lastQuestionChoiceId ===
        BEHAVIORAL_HEALTH_QUESTIONNAIRE_HOW_DIFFICULT_ANSWER_EXTREMELY
    );
  };

  const getScore = (answers?: CustomQuestionnaireAnswer[]) => {
    if (!answers) {
      return 0;
    }
    return Number(
      (
        answers.filter(
          (answer) => answer.questionId === "questionnaire-vars"
        )[0].fieldValue as CustomQuestionnaireChoice[]
      ).filter((choice) => choice.choiceId === "score")[0].value
    );
  };

  const score1 = getScore(answers?.phqAnswers);
  const score2 = getScore(answers?.gadAnswers);

  const howDifficultToWorkQuestionChoiceId =
    answers?.gadAnswers &&
    (
      answers.gadAnswers.filter(
        (x) =>
          x.questionId ===
          BEHAVIORAL_HEALTH_QUESTIONNAIRE_HOW_DIFFICULT_QUESTION_ID
      )[0]?.fieldValue as CustomQuestionnaireChoice
    )?.choiceId;

  const higherScore = Math.max(score1, score2);
  let statement:
    | "benefitFromMentalHealthSupport"
    | "connectWithMentalHealthSupport"
    | "" = "";
  if (
    (higherScore >= 5 &&
      higherScore <= 9 &&
      reportsSomewhatVeryOrExtremely(howDifficultToWorkQuestionChoiceId)) ||
    higherScore >= 10
  ) {
    statement = "connectWithMentalHealthSupport";
  } else if (
    (higherScore > 0 &&
      reportsSomewhatVeryOrExtremely(howDifficultToWorkQuestionChoiceId)) ||
    (higherScore >= 5 && higherScore <= 9)
  ) {
    statement = "benefitFromMentalHealthSupport";
  }

  return (
    <InDialog
      popup
      onClose={handleClose}
      title={translate("prompt.behavior_questionnaire")}
      setRef={setOverlayRef}
      returnUrl={"/app"}
      backdropDismiss={false}
      height={"800px"}
      width={"700px"}
    >
      <CenterQuestionnaireContent>
        {currentQuestionnaire === "phq" && (
          <CustomSuspense>
            <AsyncCustomQuestionnaire
              autoSkip={true}
              resetScrollOnEachStep
              id={BEHAVIORAL_HEALTH_QUESTIONNAIRE_PHQ8}
              useRouting={false}
              onLastStepCompleted={() => {
                setCurrentQuestionnaire("gad");
              }}
            />
          </CustomSuspense>
        )}
        {currentQuestionnaire === "gad" && (
          <CustomSuspense>
            <AsyncCustomQuestionnaire
              autoSkip={true}
              resetScrollOnEachStep
              id={BEHAVIORAL_HEALTH_QUESTIONNAIRE_GAD7}
              useRouting={false}
              onLastStepCompleted={() => {
                setCurrentQuestionnaire("completed");
              }}
            />
          </CustomSuspense>
        )}
        {currentQuestionnaire === "completed" && (
          <CenterEl>
            {higherScore === 0 && (
              <div>
                <IconCheckmarkCompleted />
                <>
                  <h1 className="as-h4" style={{ marginTop: "1rem" }}>
                    <Translate msg="questionnaire.completed" />
                  </h1>
                  <p className="m0 color-c-80" style={{ marginTop: "1rem" }}>
                    <Translate msg="questionnaire.thankYouMessage" />
                  </p>
                </>
                <Button
                  onPress={handleClose}
                  theme="charcoal"
                  size="large"
                  style={{ margin: "2rem auto" }}
                >
                  <Translate msg="button.backToApp" />
                </Button>
              </div>
            )}
            {statement && (
              <p>
                <Translate
                  msg="behavior_questionnaire"
                  variables={{ context: statement }}
                />
              </p>
            )}
            {higherScore > 0 && (
              <p className="strong">
                <Translate msg="lifeline.crisis" />
              </p>
            )}
          </CenterEl>
        )}
      </CenterQuestionnaireContent>
    </InDialog>
  );
};

export default InAppMultipleQuestionnairesDialog;
