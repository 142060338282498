import { useBlocNext } from "@blac/react";
import { FC, useEffect } from "react";
import React from "react";
import MedicalInsuranceCardDetailsForm from "./MedicalInsuranceCardDetailsForm";
import MedicalInsuranceFormBloc, {
  AddMedicalInsuranceInfoAction
} from "./MedicalInsuranceFormBloc";
import MedicalInsuranceSelectHealthPlanForm from "./MedicalInsuranceSelectHealthPlanForm";
import MedicalInsuranceUploadPhoto from "./MedicalInsuranceUploadPhoto";
import MedicalInsuranceSubscriberInformationForm from "components/MedicalInsuranceForm/MedicalInsuranceSubscriberInformationForm";

const MedicalInsuranceForm: FC<{ onStepChange: () => void }> = (props) => {
  const [{ step }, { setInitialStep }] = useBlocNext(MedicalInsuranceFormBloc);

  useEffect(() => {
    props.onStepChange();
  }, [step]);

  useEffect(() => {
    setInitialStep();
  }, []);

  switch (step) {
    case AddMedicalInsuranceInfoAction.SELECT_HEALTH_PLAN:
      return <MedicalInsuranceSelectHealthPlanForm />;
    case AddMedicalInsuranceInfoAction.SUBSCRIBER_INFO:
      return <MedicalInsuranceSubscriberInformationForm />;
    case AddMedicalInsuranceInfoAction.ADD_CARD_DETAILS:
      return <MedicalInsuranceCardDetailsForm />;
    case AddMedicalInsuranceInfoAction.ADD_CARD_PHOTOS:
      return <MedicalInsuranceUploadPhoto />;
  }
};

export default MedicalInsuranceForm;
