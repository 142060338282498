import React from "react";
const IconCardPhoto = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    fill="none"
    viewBox="0 0 30 30"
  >
    <path
      fill="#212121"
      d="M23.2 3.6h4.4v4.5h-1V4.5h-3.4v-.9Zm0 21.9h3.5V22h.9v4.4h-4.4v-1ZM2.4 3.6h4.5v1H3.3V8h-.9V3.6Zm0 18.4h1v3.5h3.5v.9H2.4V22ZM14.5 18H9c0-.3 0-.6.2-.8 0-.2.3-.3.5-.4a7.5 7.5 0 0 1 2.1-.4h1l1.2.4c.2 0 .3.2.5.4l.1.7v.1ZM12.6 15.1c-.2.3-.5.4-.9.4-.3 0-.6-.1-.8-.4-.2-.2-.3-.5-.3-.8 0-.3 0-.6.3-.8.2-.3.5-.4.8-.4.4 0 .7.1.9.4.2.2.3.5.3.8 0 .3 0 .6-.3.8Z"
    />
    <path
      fill="#212121"
      fillRule="evenodd"
      d="M5.9 21.5c.3.2.6.4 1 .4h16.2c.4 0 .7-.2 1-.4.3-.3.4-.7.4-1v-11A1.4 1.4 0 0 0 23 8h-6l-1.2.1H7c-.4 0-.7.2-1 .4-.3.3-.4.7-.4 1v11c0 .3.1.7.4 1Zm11.3-.4H6.9l-.4-.2a.6.6 0 0 1-.2-.5V9.6c0-.2 0-.4.2-.5l.4-.2h15.4c-.1.7-.4 2.3-.9 4.1h-4.2v.9h4l-.6 1.6h-3.4v.8h3c-.7 1.8-1.7 3.6-3 4.8Z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconCardPhoto;
