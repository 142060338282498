import { css } from "@emotion/css";
import styled from "@emotion/styled";
import type { FC } from "react";
import React, { useRef } from "react";
import { APP_CONTENT_WIDTH } from "src/constants/layout";
import translate from "src/lib/translate";
import InDialog from "src/ui/components/InDialog/InDialog";
import useGoToOrBack from "src/ui/hooks/useGoToOrBack";
import MedicalInsuranceForm from "../../MedicalInsuranceForm/MedicalInsuranceForm";
import { AppQueryPopupsController } from "../AppQueryPopupsBloc";
import WithKeyboardPadding from "../../WithKeyboardPadding/WithKeyboardPadding";

const CenterContent = styled.div`
  display: flex;
  align-items: center;
  min-height: 100%;

  > div {
    flex: 1;
  }

  section {
    width: 100%;
    padding: 0 1rem;
    margin: 2rem auto;
    max-width: ${APP_CONTENT_WIDTH}px;
  }
`;

const ionModalStyles = css`
  // popup options for iframe
  ion-content {
    --padding-start: 0;
    --padding-end: 0;
  }
`;

const EnterMedicalInsurance: FC<{
  returnUrl: string;
}> = ({ returnUrl }) => {
  const navigate = useGoToOrBack();
  const elRef = useRef<HTMLDivElement>(null);

  const onClose = () => {
    AppQueryPopupsController.closePopup();
    navigate(returnUrl, {
      multiBack: true,
      replace: true
    });
  };

  return (
    <InDialog
      popup
      title={translate("medicalInsuranceCard")}
      onClose={onClose}
      returnUrl={returnUrl}
      className={ionModalStyles}
    >
      <div ref={elRef} style={{ scrollMarginTop: "100vh" }} />
      <WithKeyboardPadding>
        <CenterContent>
          <MedicalInsuranceForm
            onStepChange={() =>
              elRef.current?.scrollIntoView({ behavior: "smooth" })
            }
          />
        </CenterContent>
      </WithKeyboardPadding>
    </InDialog>
  );
};

export default EnterMedicalInsurance;
