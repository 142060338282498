import type { FC } from "react";
import React from "react";
import translate from "src/lib/translate";
import BloodGlucoseForm from "src/ui/components/BloodGlucoseForm/BloodGlucoseForm";
import InDialog from "src/ui/components/InDialog/InDialog";
import useGoToOrBack from "src/ui/hooks/useGoToOrBack";
import { AppQueryPopupsController } from "../../AppQueryPopups/AppQueryPopupsBloc";

const BloodGlucoseDialog: FC<{
  returnUrl: string;
}> = ({ returnUrl }) => {
  const navigate = useGoToOrBack();

  const onClose = (dispatch?: boolean) => {
    AppQueryPopupsController.closePopup();
    if (dispatch) {
      document.dispatchEvent(
        new CustomEvent("dataSubmitted", { bubbles: true })
      );
    }
    navigate(returnUrl, {
      multiBack: true,
      replace: true
    });
  };

  return (
    <InDialog
      popup
      title={translate("upload_labs.title")}
      returnUrl={returnUrl}
      onClose={onClose}
    >
      <nine-spacer s="md"></nine-spacer>

      <BloodGlucoseForm onComplete={() => onClose(true)} />
    </InDialog>
  );
};

export default BloodGlucoseDialog;
