/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Holds data that is required for an assume identity operation.
 */
export type UserAssumeIdentityAdminRequest = {
    /**
     * The user ID that should be assumed.
     */
    userId: string;
    /**
     * The role to switch to.
     */
    role: UserAssumeIdentityAdminRequest.role;
    /**
     * A reasoning why the identity was assumed.
     */
    reasoning: string;
};

export namespace UserAssumeIdentityAdminRequest {

    /**
     * The role to switch to.
     */
    export enum role {
        ANONYMOUS = 'ANONYMOUS',
        CHANGE_PASSWORD = 'CHANGE_PASSWORD',
        API_DOCUMENTATION = 'API_DOCUMENTATION',
        USER = 'USER',
        STAFF_VIEW_ONLY = 'STAFF_VIEW_ONLY',
        STAFF = 'STAFF',
        STAFF_ADMIN_ACCESS = 'STAFF_ADMIN_ACCESS',
        STAFF_CUSTOMER_SUPPORT = 'STAFF_CUSTOMER_SUPPORT',
        STAFF_DIABETES_EDUCATOR = 'STAFF_DIABETES_EDUCATOR',
        STAFF_PROVIDER = 'STAFF_PROVIDER',
        STAFF_PHYSICIAN = 'STAFF_PHYSICIAN',
        STAFF_PHYSICIAN_ASSISTANT = 'STAFF_PHYSICIAN_ASSISTANT',
        STAFF_NURSE_PRACTITIONER = 'STAFF_NURSE_PRACTITIONER',
        DEVELOPER = 'DEVELOPER',
        SUPER_ADMIN = 'SUPER_ADMIN',
    }


}

