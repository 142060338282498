import envVariables from "src/lib/envVariables";
import reportErrorSentry from "src/lib/reportErrorSentry";

export interface VimeoPictureSize {
  width?: number;
  height?: number;
  link?: string;
  link_with_play_button?: string;
}

export interface VimeoPicture {
  uri?: string;
  active?: boolean;
  base_link?: string;
  default_picture?: boolean;
  resource_key?: string;
  type?: string;
  sizes?: VimeoPictureSize[];
}

export interface VimeoVideoFile {
  quality: "sd" | "hd" | "hls";
  rendition: "360p" | "540p" | "720p" | "1080p" | "adaptive";
  width?: number;
  height?: number;
  link: string;
}

export interface VimeoVideoMetaData {
  pictures?: VimeoPicture;
  player_embed_url?: string;
  files?: VimeoVideoFile[];
  name?: string;
  width?: number;
  height?: number;
}

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export default class VimeoService {
  public static metaPromise: Record<
    string,
    Promise<VimeoVideoMetaData> | undefined
  > = {};
  public static fetchVideoMetadata = async (
    id: number | string
  ): Promise<VimeoVideoMetaData> => {
    const runningPromise = VimeoService.metaPromise[id];

    if (runningPromise) {
      return runningPromise;
    }

    const p = new Promise<VimeoVideoMetaData>((resolve, reject) => {
      fetch(`https://api.vimeo.com/videos/${id}`, {
        headers: {
          Accept: "application/vnd.vimeo.*+json;version=3.4",
          Authorization: `bearer ${envVariables.VIMEO_TOKEN}`
        }
      })
        .then(async (response) => {
          if (!response.ok) {
            reject(new Error("[VIMEO] Response not ok"));
            return;
          }

          const json = await response.json();
          resolve(json as VimeoVideoMetaData);
          VimeoService.metaPromise[id] = Promise.resolve(
            json as VimeoVideoMetaData
          );
        })
        .catch((error: unknown) => {
          VimeoService.metaPromise[id] = undefined;
          reject(error);
        });
    });

    VimeoService.metaPromise[id] = p;

    return p;
  };

  public static getVideoMeta = async (
    id: number | string
  ): Promise<VimeoVideoMetaData> => {
    try {
      const meta = await VimeoService.fetchVideoMetadata(id);
      return meta;
    } catch (error) {
      reportErrorSentry(error);
      return {};
    }
  };

  static extractIdFromUrl = (url: string): number | null => {
    // get id
    const id = /\d{10}/.exec(url) ?? /\d{9}/.exec(url);

    // check if url matches vimeo pattern
    if (/vimeo\.com/.exec(url)) {
      return id ? parseInt(id[0], 10) : null;
    }

    return null;
  };
}
