import React, { useEffect, useMemo } from "react";
import MaskInput from "lib/maskInput";
import {
  DescriptionWrap,
  IconWrap,
  InputEl,
  InputFieldErrorEl,
  InputLabelEl,
  InputProps,
  InputTextEl,
  InputTextFieldEl,
  InputWrap
} from "atom/input/inputComponents";
import { FormFieldBaseState } from "atom/autoform/AutoFormBloc";
import IMask from "imask";
import { ErrorIcon, XCrossIcon } from "constants/Icon";
import { EyeIcon } from "../../../icons/EyeIcon";
import { EyeClosedIcon } from "../../../icons/EyeClosedIcon";

function InputNoRef(
  props: InputProps,
  ref: React.Ref<FormFieldBaseState | null>
) {
  const [value, setValue] = React.useState(props.defaultValue ?? "");
  const {
    label,
    mask,
    onChangeCapture,
    error,
    description,
    DescriptionIcon,
    errorParser = ({ error }) => {
      if (!error) return "";
      if (typeof error === "string") return error;
      return error.message;
    },
    ...textFieldProps
  } = props;
  const elRef = React.useRef<HTMLInputElement>(null);

  const isPasswordToggleEnabled =
    props.type === "password" && props.isPasswordToggleEnabled;

  const [seePassword, setSeePassword] = React.useState(false);

  const handlePasswordToggleClick = () => {
    setSeePassword(!seePassword);
  };

  React.useEffect(() => {
    if (!elRef.current || !mask) return;
    const instance = new MaskInput(elRef.current, mask);
    return () => {
      instance.destroy();
    };
  }, [elRef.current, mask]);

  React.useImperativeHandle(
    ref,
    () => ({
      setValue
    }),
    [elRef]
  );

  const pipedValue = useMemo(() => {
    if (!mask) return value;
    return IMask.pipe(value, mask);
  }, [mask, value]);

  useEffect(() => {
    textFieldProps.onChange?.(pipedValue);
  }, [pipedValue]);


  const errorString = errorParser(props);

  const handlePasteCapture = (e: React.ClipboardEvent<HTMLInputElement>) => {
    if (props.disableMaskFormattingOnPaste) return;

    const value = e.clipboardData.getData("text");
    if (mask && elRef.current) {
      React.startTransition(() => {
        const piped = IMask.pipe(value, mask);
        setValue(piped);
      });
    }
  };

  return (
    <InputTextFieldEl
      {...textFieldProps}
      type={seePassword ? "text" : props.type}
      value={pipedValue}
      onChange={(e) => {
        setValue(e);
      }}
      ref={null}
      className="input"
      isInvalid={Boolean(error)}
    >
      <InputWrap>
        <InputEl
          value={pipedValue}
          ref={elRef}
          onChangeCapture={onChangeCapture}
          onPasteCapture={handlePasteCapture}
        />
        {label && <InputLabelEl className="body1">{label}</InputLabelEl>}

        <IconWrap>
          {Boolean(error) && props.type !== "password" && <ErrorIcon />}

          {props.clearable && !error && (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                setValue("");
                elRef.current?.focus();
              }}
            >
              <XCrossIcon />
            </div>
          )}

          {isPasswordToggleEnabled && (
            <div onClick={handlePasswordToggleClick}>
              {seePassword ? <EyeClosedIcon /> : <EyeIcon />}
            </div>
          )}
        </IconWrap>
      </InputWrap>
      {description && (
        <DescriptionWrap>
          {DescriptionIcon && (
            <div style={{ marginTop: "0.5rem" }}>
              <DescriptionIcon />
            </div>
          )}

          <InputTextEl slot="description" className="little1">
            {description}
          </InputTextEl>
        </DescriptionWrap>
      )}
      <InputFieldErrorEl className={"little1 data-field-error"}>
        {errorString}
      </InputFieldErrorEl>
    </InputTextFieldEl>
  );
}

const Input = React.forwardRef(InputNoRef) as typeof InputNoRef;
export default Input;
