/* eslint-disable no-console */
import React from "react";
import DebugAuthQuery from "src/ui/components/Debug/DebugAuthQuery";
import Payment from "src/ui/components/Payment/Payment";
import { PaymentContextWithPaymentCubit } from "src/ui/components/PaymentContext/PaymentContext";
import WithAuth from "src/ui/components/WithAuth/WithAuth";

const DebugAddPayment = () => {
  const subId = "123";
  return (
    <>
      <DebugAuthQuery />
      <WithAuth createAnonymousUser>
        <PaymentContextWithPaymentCubit>
          <Payment
            subscriptionId={subId}
            buyAction={async () => false}
            onError={console.log}
            onComplete={console.log}
          />
        </PaymentContextWithPaymentCubit>
      </WithAuth>
    </>
  );
};

export default DebugAddPayment;
