import {
  AutoForm,
  AutoFormAutocompleteField,
  Button,
  useAutoFormControls
} from "@9amhealth/shared";
import { NineHeading } from "@9amhealth/wcl/generated/react";
import { useBlocNext } from "@blac/react";
import styled from "@emotion/styled";
import React, { useEffect } from "react";
import { Item } from "react-stately";
import translate from "src/lib/translate";
import Translate from "src/ui/components/Translate/Translate";
import MedicalInsuranceFormBloc, {
  schemaHealthplan
} from "./MedicalInsuranceFormBloc";

const FormFieldsWrapper = styled.div`
  padding: 0 0.6rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const MedicalInsuranceSelectHealthPlanForm = () => {
  const [
    { initialHealthPlanFormValues, healthPlanOptions = [] },
    {
      defineHealthPlanFormControls,
      handleFormSubmitHealthplan,
      fetchHealthPlanOptions
    }
  ] = useBlocNext(MedicalInsuranceFormBloc);

  useEffect(() => {
    void fetchHealthPlanOptions();
  }, []);

  const autoFormHealthplan = useAutoFormControls({
    schema: schemaHealthplan,
    onSubmit: handleFormSubmitHealthplan,
    initialValue: initialHealthPlanFormValues ?? { planId: "" }
  });

  defineHealthPlanFormControls(autoFormHealthplan);

  useEffect(() => {
    if (initialHealthPlanFormValues) {
      autoFormHealthplan.setValues(initialHealthPlanFormValues);
    }
  }, [initialHealthPlanFormValues]);

  return (
    <section>
      <NineHeading>
        <h3 className="as-h4-large">
          <Translate msg="insurance.medical.healthPlan.title" />
        </h3>
      </NineHeading>
      <nine-spacer s="lg"></nine-spacer>

      <AutoForm {...autoFormHealthplan.props}>
        <FormFieldsWrapper>
          <AutoFormAutocompleteField
            defaultItems={healthPlanOptions}
            name="planId"
            label={translate("form.label_medicalInsuranceHealthPlan")}
          >
            {(item) => <Item key={item.id}>{item.label}</Item>}
          </AutoFormAutocompleteField>
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              marginTop: "1rem"
            }}
          >
            <Button type="submit">
              <Translate msg="continue" />
            </Button>
          </div>
        </FormFieldsWrapper>
      </AutoForm>
    </section>
  );
};

export default MedicalInsuranceSelectHealthPlanForm;
