import {
  AutoForm,
  AutoFormAutocompleteField,
  AutoFormInputField,
  Button,
  useAutoFormControls
} from "@9amhealth/shared";
import { NineHeading } from "@9amhealth/wcl/generated/react";
import { useBlocNext } from "@blac/react";
import styled from "@emotion/styled";
import React from "react";
import { Item } from "react-stately";
import Translate from "src/ui/components/Translate/Translate";
import MedicalInsuranceFormBloc from "./MedicalInsuranceFormBloc";
import { z } from "zod";
import { GuarantorItem } from "@9amhealth/openapi";
import { getAvailableStates } from "src/lib/getAvailableStates";
import { AutocompleteItem } from "molecule/autocomplete/Autocomplete";
import translate from "src/lib/translate";
import {
  CITY_SCHEMA_MESSAGES,
  FIRST_NAME_SCHEMA_MESSAGES,
  LAST_NAME_SCHEMA_MESSAGES,
  SEX_SCHEMA_MESSAGES,
  STATE_SCHEMA_MESSAGES,
  STREET_ADDRESS_SCHEMA_MESSAGES,
  ZIP_CODE_SCHEMA_MESSAGES
} from "src/constants/schemaValidation";

const states = getAvailableStates();

const sexFieldOptions: AutocompleteItem[] = Object.values(
  GuarantorItem.sex
).map((sex) => ({
  id: sex,
  label: sex,
  name: sex
}));

const FormFieldsWrapper = styled.div`
  padding: 0 0.6rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const subscriberSchema = z.object({
  firstName: z.string(FIRST_NAME_SCHEMA_MESSAGES),
  lastName: z.string(LAST_NAME_SCHEMA_MESSAGES),
  street: z.string(STREET_ADDRESS_SCHEMA_MESSAGES),
  aptSuite: z.string().optional(),
  city: z.string(CITY_SCHEMA_MESSAGES),
  sex: z.nativeEnum(GuarantorItem.sex, SEX_SCHEMA_MESSAGES),
  zipCode: z.string(ZIP_CODE_SCHEMA_MESSAGES).regex(/^[0-9]{5}$/, {
    message: translate("zip_invalid")
  }),
  state: z.nativeEnum(GuarantorItem.state, STATE_SCHEMA_MESSAGES)
});

const MedicalInsuranceSubscriberInformationForm = () => {
  const [
    { initialSubscriberInformationFormValues },
    { handleFormSubmitSubscriberInfo }
  ] = useBlocNext(MedicalInsuranceFormBloc);

  const autoFormGuarantorInformation = useAutoFormControls({
    schema: subscriberSchema,
    onSubmit: handleFormSubmitSubscriberInfo,
    initialValue: initialSubscriberInformationFormValues
  });

  return (
    <section>
      <NineHeading>
        <h3 className="as-h4-large">
          <Translate msg="insurance.medical.subscriberInfo.title" />
        </h3>
      </NineHeading>
      <nine-spacer s="lg"></nine-spacer>

      <AutoForm {...autoFormGuarantorInformation.props}>
        <FormFieldsWrapper
          style={{
            marginBottom: "10rem"
          }}
        >
          <AutoFormInputField
            name="firstName"
            label="First Name"
          ></AutoFormInputField>

          <AutoFormInputField
            name="lastName"
            label="Last Name"
          ></AutoFormInputField>

          <AutoFormAutocompleteField
            name="sex"
            label="Sex"
            defaultItems={sexFieldOptions}
          >
            {(item: AutocompleteItem) => (
              <Item key={item.id}>{item.label}</Item>
            )}
          </AutoFormAutocompleteField>

          <AutoFormAutocompleteField
            name="state"
            label="State"
            defaultItems={states}
          >
            {(item: AutocompleteItem) => (
              <Item key={item.id}>{item.label}</Item>
            )}
          </AutoFormAutocompleteField>

          <AutoFormInputField name="street" label="Street"></AutoFormInputField>

          <AutoFormInputField
            name="aptSuite"
            label="Apt/Suite"
          ></AutoFormInputField>

          <AutoFormInputField name="city" label="City"></AutoFormInputField>

          <AutoFormInputField
            name="zipCode"
            label="ZIP"
            mask="00000"
          ></AutoFormInputField>

          <div
            style={{
              justifyContent: "center",
              display: "flex",
              marginTop: "1rem"
            }}
          >
            <Button type="submit">
              <Translate msg="continue" />
            </Button>
          </div>
        </FormFieldsWrapper>
      </AutoForm>
    </section>
  );
};

export default MedicalInsuranceSubscriberInformationForm;
