import React from "react";
import useAutoFormField from "atom/autoform/useAutoFormField";
import Autocomplete, {
  AutocompleteItem,
  AutocompleteProps
} from "molecule/autocomplete/Autocomplete";

function AutoFormAutocompleteField<T extends AutocompleteItem>(
  componentProps: AutocompleteProps<T> & { name: string }
) {
  const [autoFormFieldProps] = useAutoFormField<AutocompleteProps<T>>(
    componentProps,
    "autocomplete"
  );

  if (!autoFormFieldProps || !autoFormFieldProps.name) return null;

  return <Autocomplete {...componentProps} {...autoFormFieldProps} />;
}

export default AutoFormAutocompleteField;
