import { FC, ReactElement, ReactNode, useEffect } from "react";
import React, { useMemo } from "react";
import ProfileCubit from "src/state/ProfileCubit/ProfileCubit";
import useUserAuthStage from "src/state/hooks/useUserAuthStage";
import { BlocProvider, useBloc, userPreferences } from "src/state/state";
import LoginOverlay from "src/ui/components/LoginOverlay/LoginOverlay";
import Loader from "../Loader/Loader";
import SubscriptionCubit from "src/state/SubscriptionCubit/SubscriptionCubit";
import { useNavigate } from "react-router-dom";
import UserCubit from "src/state/UserCubit/UserCubit";
import ReAuthenticateUsingTranscarent from "components/LoginForm/ReAuthenticateUsingTranscarent";
import { useBlocNext } from "@blac/react";
import { View, WithAuthCubit } from "components/WithAuth/WithAuthCubit";

export interface Props {
  redirectIfNoToken?: boolean;
  loadProfile?: boolean;
  createAnonymousUser?: boolean;
  children?: ReactNode | undefined;
  allowPartialAuth?: boolean;
  /** If true, the user is redirected to a signup funnel if they don't have an active subscription */
  requiresSubscription?: boolean;
}

const WithAuth: FC<Props> = (props): ReactElement => {
  const navigate = useNavigate();
  const profileBloc = useMemo(() => new ProfileCubit(), []);
  const authStage = useUserAuthStage();
  const [{ allSubscriptions }] = useBloc(SubscriptionCubit);

  const [{ userData }, { partnerId }] = useBloc(UserCubit);

  const [{ view }, { initializeDependencies }] = useBlocNext(WithAuthCubit, {
    props
  });

  useEffect(() => {
    initializeDependencies({
      props,
      navigate,
      allSubscriptions,
      payerId: partnerId,
      sessionAuthenticationMethod: userData?.sessionAuthenticationMethod,
      authStage
    });
  }, [
    props,
    navigate,
    allSubscriptions,
    partnerId,
    userData,
    authStage,
    initializeDependencies
  ]);

  if (view === View.App) {
    return (
      <BlocProvider bloc={userPreferences}>
        <BlocProvider bloc={profileBloc}>
          <>{props.children}</>
        </BlocProvider>
      </BlocProvider>
    );
  }

  if (view === View.Loading) {
    return <Loader gradient active fixed />;
  }

  if (view === View.Reauth) {
    return <ReAuthenticateUsingTranscarent />;
  }

  return <LoginOverlay />;
};

export default WithAuth;
