import { Cubit } from "blac-next";
import FunnelKey from "src/constants/funnelKey";
import type {
  HeaderStep,
  SignupState
} from "src/state/SignupCubit/SignupState";
import {
  SignupStateInitial,
  SignupStatePurchaseFlow
} from "src/state/SignupCubit/SignupState";

export default class SignupCubit extends Cubit<SignupState> {
  headerSteps: HeaderStep[] = [];
  constructor(options: { headerSteps?: HeaderStep[] } = {}) {
    super(new SignupStateInitial());
    this.headerSteps = options.headerSteps ?? [];
  }

  static readonly loadSupportChat = (): void => {
    const isTcFunnel = window.location.href.includes(FunnelKey.transcarent);
    if (isTcFunnel) {
      return;
    }
    const launcher = document.getElementById("launcher");

    if (launcher) {
      launcher.style.display = "block";
    } else {
      const ZDS = document.createElement("script");
      ZDS.src =
        "https://static.zdassets.com/ekr/snippet.js?key=9266466b-d14d-43a5-918e-65f26761ec48";
      ZDS.id = "ze-snippet";
      ZDS.async = true;

      document.head.appendChild(ZDS);
    }
  };

  static readonly removeSupportChat = (): void => {
    document.querySelector("[title*=messaging]")?.remove();
    [...document.querySelectorAll(`[style*="z-index: 999999;"]`)].forEach(
      (el) => {
        (el as HTMLDivElement).remove();
      }
    );
    document.querySelector(`[data-product="web_widget"]`)?.remove();
  };

  public readonly setBackButton = (show: boolean): void => {
    if (this.state instanceof SignupStatePurchaseFlow) {
      this.emit(
        new SignupStatePurchaseFlow({
          ...this.state,
          showBackButton: show
        })
      );
    }
  };

  readonly hideSignupHeaderForPath = (pathname: string): boolean => {
    return pathname.includes("success") || pathname.includes("whats-next");
  };
}
