import React from "react";
import styled from "@emotion/styled";
import clsx from "clsx";
import {
  CheckmarkMessageDelivered,
  CheckmarkMessageSent
} from "src/constants/icons";
import type { ChatMessage } from "src/ui/components/Chat/ChatBloc";

interface MessageProps {
  message: ChatMessage;
  profileImage?: string;
  children?: React.ReactNode;
  openDetails?: () => void;
}

const Wrap = styled.div`
  label: Message;
  margin-top: 1.2rem;
  display: grid;
  grid-template-columns: 3.2em minmax(0, 1fr);
  align-items: flex-end;
`;

const Profile = styled.div<{ profileImage: string }>`
  label: Profile;
  width: 3.2em;
  min-width: 3.2em;
  height: 3.2em;
  border-radius: 50%;
  background-color: var(--color-cream);
  background-size: cover;
  background-position: center;
  background-image: url(${({ profileImage }) => profileImage});
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-charcoal);
  font-size: 0.9em;
  font-weight: 500;
`;

const DateTime = styled.div`
  position: absolute;
  font-size: 0.8em;
  color: #aaa7a0;
  right: 0;
  bottom: -1.6rem;
  width: 9rem;
  text-align: end;
  display: flex;
  justify-content: flex-end;
  gap: 0.2rem;

  .confirmation {
    display: none;
  }

  &.user-message {
    .confirmation {
      display: block;
    }
  }
`;

const Card = styled.div`
  background: white;
  padding: 1em 1em 0.8em;
  border-radius: 0.5em;
  position: relative;
  max-width: var(--msg-max-w, 100%);
  width: var(--msg-w, auto);
  box-sizing: border-box;
  box-shadow: var(--light-shadow);
  z-index: 0;

  .divider {
    width: 100%;
    height: 1px;
    background-color: var(--color-charcoal);
    opacity: 0.1;
    margin: 0.6rem 0;
  }

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    border-radius: inherit;
    background: inherit;
    z-index: -2;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 2em;
    height: 2em;
    background-color: inherit;
    border-radius: 10%;
    transform: rotate(45deg);
    bottom: 0.48em;
    right: calc(100% - 1.7em);
    z-index: -1;
  }

  &.user-message {
    background: var(--color-midmorning-blue);

    .divider {
      display: none;
    }

    &::before,
    &::after {
      content: none;
    }
  }

  &.is-plain-message {
    padding: 0;
    background: none;
    box-shadow: none;

    &::before,
    &::after {
      content: none;
    }
  }

  &.hide-arrow {
    .msg::after {
      display: none;
    }
  }
`;

const Positioner = styled.div`
  label: MsgPosition;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 0.8em;

  &.user-message {
    align-items: flex-end;
  }
`;

const Footer = styled.div`
  margin-top: 0.8em;
  font-size: 0.9em;
  color: var(--color-charcoal-60);
  font-weight: 400;

  &.user-message {
    display: none;
  }
`;

const Name = styled.div`
  font-weight: 500;
  color: var(--color-charcoal-80);
`;

enum MessageType {
  ATTACHMENT = "attachment",
  LAB_VALUE = "labValue",
  TEXT = "text"
}

export const getMessageType = (message: ChatMessage): MessageType => {
  const hasFileAttachments = message.computed.fileAttachments.length > 0;

  if (hasFileAttachments) {
    return MessageType.ATTACHMENT;
  }

  if (message.computed.contentValueGenerated) {
    return MessageType.LAB_VALUE;
  }

  return MessageType.TEXT;
};

export const MessageComponent: React.FC<MessageProps> = ({
  message,
  profileImage,
  openDetails,
  children
}) => {
  const {
    senderName,
    messageSentDate,
    isLocal,
    contentValueGenerated,
    senderDescription
  } = message.computed;

  const messageType = getMessageType(message);

  /*
   * Lab values are always considered user messages.
   */
  const userRelatedMessage =
    message.computed.sentByUser || messageType === MessageType.LAB_VALUE;

  const hideAvatar =
    messageType === MessageType.ATTACHMENT || userRelatedMessage;

  /*
   * A message is plain when has no styling or extra information.
   * Should only be positioned.
   */
  const isPlainMessage =
    ([MessageType.ATTACHMENT, MessageType.LAB_VALUE].includes(messageType) &&
      message.contentValueGenerated === true) ||
    !message.text?.length;

  return (
    <Wrap
      style={
        {
          "--msg-w": contentValueGenerated ? "100%" : "auto"
        } as React.CSSProperties
      }
    >
      <div>
        {!hideAvatar && (
          <Profile profileImage={profileImage ?? ""} onClick={openDetails} />
        )}
      </div>
      <Positioner
        className={clsx({
          "user-message": userRelatedMessage
        })}
      >
        <Card
          className={clsx({
            "is-plain-message": isPlainMessage,
            "user-message": userRelatedMessage,
            "hide-arrow": hideAvatar
          })}
        >
          {children}

          <div className="divider" />

          <Footer
            className={clsx({
              "user-message": userRelatedMessage
            })}
            onClick={openDetails}
          >
            <Name>{senderName}</Name>
            <div>{senderDescription}</div>
          </Footer>

          <DateTime
            className={clsx({
              "user-message": userRelatedMessage,
              "is-plain-message": isPlainMessage
            })}
          >
            <span>{messageSentDate}</span>
            <div className="confirmation">
              {isLocal ? (
                <CheckmarkMessageSent />
              ) : (
                <CheckmarkMessageDelivered />
              )}
            </div>
          </DateTime>
        </Card>
      </Positioner>
    </Wrap>
  );
};
