import {
  AutocompleteErrorEl,
  AutocompleteIcon,
  AutocompleteInputEl,
  AutocompleteInputWrap,
  AutocompleteLabelEl,
  AutocompleteTextEl
} from "atom/autocomplete/autocompleteComponents";
import { DropdownState, FormFieldState } from "atom/autoform/AutoFormBloc";
import styled from "@emotion/styled";
import { useElementDimensions } from "lib/useElementDimensions";
import { ListBox } from "molecule/dropdown/Dropdown";
import React, { useEffect } from "react";
import {
  AriaComboBoxProps,
  AriaPopoverProps,
  DismissButton,
  Key,
  Overlay,
  useComboBox,
  useFilter,
  usePopover
} from "react-aria";
import { Button } from "react-aria-components";
import { OverlayTriggerState, useComboBoxState } from "react-stately";
import { z } from "zod";

const AutocompleteCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 80vh;
  width: 100%;

  &[data-loading="true"] {
    filter: blur(2px);
    pointer-events: none;
  }
`;

interface AutoCompletePopoverProps extends AriaPopoverProps {
  children: React.ReactNode;
  state: OverlayTriggerState;
}

function AutoCompletePopover({
  children,
  state,
  ...props
}: AutoCompletePopoverProps) {
  const { popoverProps } = usePopover(props, state);

  return (
    <Overlay>
      <div
        {...popoverProps}
        style={{
          ...popoverProps.style,
          overflowY: "scroll",
          backgroundColor: "white",
          boxShadow:
            "-82px 81px 46px rgba(69, 49, 22, 0.01), -46px 45px 39px rgba(69, 49, 22, 0.03), -20px 20px 29px rgba(69, 49, 22, 0.07), -5px 5px 16px rgba(69, 49, 22, 0.08), 0px 0px 0px rgba(69, 49, 22, 0.08)",
          borderRadius: "8px"
        }}
        ref={props.popoverRef as React.RefObject<HTMLDivElement>}
      >
        {children}
      </div>
      <DismissButton onDismiss={state.close} />
    </Overlay>
  );
}

export type AutocompleteItem = object & {
  id: string;
  label?: string;
  name?: string;
};

export interface AutocompleteProps<T extends AutocompleteItem>
  extends AriaComboBoxProps<T> {
  errorParser?: (props: AutocompleteProps<T>) => string;
  defaultValue?: Key;
  defaultItems: T[];
  error?: string | z.ZodIssue;
  ref?: React.Ref<FormFieldState | null>;
  isLoading?: boolean;
  name?: string;
  enableInputLength?: number;
}

function AutocompleteNoRef<T extends AutocompleteItem>(
  p: AutocompleteProps<T>,
  ref?: React.Ref<FormFieldState | null>
) {
  const {
    errorParser = ({ error }) => {
      if (!error) return "";
      if (typeof error === "string") return error;
      return error.message;
    },
    isLoading,
    enableInputLength = 5,
    ...props
  } = p;
  const enableInput =
    typeof props.items !== "undefined" ||
    p.defaultItems.length >= enableInputLength;

  const { contains } = useFilter({ sensitivity: "base" });
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const listBoxRef = React.useRef<HTMLDivElement>(null);
  const popoverRef = React.useRef<HTMLDivElement>(null);
  const [hasFocus, setHasFocus] = React.useState(false);

  const state = useComboBoxState({
    ...props,
    defaultFilter: enableInput ? contains : undefined,
    onSelectionChange: (item) => {
      if (!item) return;
      inputRef.current?.blur();
      buttonRef.current?.blur();
      props.onSelectionChange?.(item);
    }
  });

  const updatePopoverPosition = () => {
    requestAnimationFrame(() => {
      if (!state.isOpen && hasFocus) {
        state.open();
      }
      if (!inputRef.current) return;
      if (!popoverRef.current) return;
      const inputRect = inputRef.current.getBoundingClientRect();
      const topPosition = inputRect.top + inputRect.height;
      popoverRef.current.style.top = `${topPosition}px`;
    });
  };

  const handleInputFocus = () => {
    setHasFocus(true);
  };

  const handleInputBlur = () => {
    setHasFocus(false);
  };

  const handleButtonPress = () => {
    setHasFocus(true);
    state.open();
  };

  const handleButtonBlur = () => {
    setHasFocus(false);
    state.close();
  };

  useEffect(() => {
    const interval = setInterval(updatePopoverPosition, 200);
    document.addEventListener("touchmove", updatePopoverPosition);
    document.addEventListener("wheel", updatePopoverPosition);
    document.addEventListener("ionScroll", updatePopoverPosition);
    document.addEventListener("nine-scroll", updatePopoverPosition);

    return () => {
      clearInterval(interval);
      document.removeEventListener("touchmove", updatePopoverPosition);
      document.removeEventListener("wheel", updatePopoverPosition);
      document.removeEventListener("ionScroll", updatePopoverPosition);
      document.removeEventListener("nine-scroll", updatePopoverPosition);
    };
  }, []);

  useEffect(() => {
    if (!enableInput) {
      return;
    }

    if (hasFocus) {
      state.open();
      setTimeout(() => {
        state.open();
      }, 100);
    } else {
      state.close();
    }
  }, [enableInput, hasFocus, state.isOpen, state.isFocused]);

  const { inputProps, listBoxProps, labelProps } = useComboBox(
    {
      ...props,
      inputRef,
      buttonRef,
      listBoxRef,
      popoverRef,
      onBlur: handleInputBlur,
      onFocus: handleInputFocus
    },
    state
  );

  const [{ width }] = useElementDimensions(inputRef);

  React.useImperativeHandle(ref, () => {
    return {
      setValue: (value: string) => {
        state.setSelectedKey(value);
      }
    } as DropdownState;
  }, [state]);

  const errorString = errorParser(props);

  useEffect(() => {
    if (props.defaultSelectedKey || props.defaultValue) {
      for (const item of props.defaultItems) {
        if (
          item.name === props.defaultValue ||
          item.label === props.defaultValue ||
          item.id === props.defaultValue ||
          item.id === props.defaultSelectedKey
        ) {
          state.setSelectedKey(String(item.id));
        }
      }
    }
  }, []);

  return (
    <>
      <div
        style={{ position: "relative", display: "inline-block", width: "100%" }}
      >
        <AutocompleteInputWrap data-invalid={!!props.isInvalid}>
          <AutocompleteInputEl
            {...inputProps}
            ref={inputRef}
            data-invalid={!!props.isInvalid}
            disabled={!enableInput}
            aria-hidden={!enableInput}
          />
          {!enableInput && (
            <Button
              ref={buttonRef}
              aria-labelledby={inputProps["aria-labelledby"]}
              aria-controls={inputProps["aria-controls"]}
              onPress={handleButtonPress}
              onBlur={handleButtonBlur}
              style={{
                position: "absolute",
                right: 0,
                top: 0,
                width: "100%",
                height: "100%",
                opacity: 0.001,
                cursor: "pointer"
              }}
            ></Button>
          )}
          {props.label && (
            <AutocompleteLabelEl
              {...labelProps}
              data-hasvalue={!!state.inputValue}
              data-expanded={state.isOpen || Boolean(state.selectedItem)}
              className="body1"
            >
              {props.label}
            </AutocompleteLabelEl>
          )}
          <AutocompleteIcon />
        </AutocompleteInputWrap>
        {state.isOpen && (
          <AutoCompletePopover
            state={state}
            triggerRef={inputRef}
            popoverRef={popoverRef}
            isNonModal
            shouldFlip={!enableInput}
            placement="bottom start"
          >
            <AutocompleteCard data-loading={isLoading} style={{ width }}>
              <ListBox
                {...listBoxProps}
                listBoxRef={listBoxRef}
                state={state}
              />
            </AutocompleteCard>
          </AutoCompletePopover>
        )}
        {props.description && (
          <AutocompleteTextEl slot="description" className="little1">
            {props.description}
          </AutocompleteTextEl>
        )}
        {errorString && (
          <AutocompleteErrorEl className={"little1 data-field-error"}>
            {errorString}
          </AutocompleteErrorEl>
        )}
      </div>
    </>
  );
}

const Autocomplete = React.forwardRef(
  AutocompleteNoRef
) as typeof AutocompleteNoRef;

export default Autocomplete;
