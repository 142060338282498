import React from "react";
import useAutoFormField from "atom/autoform/useAutoFormField";
import { DataFieldProps } from "atom/datafield/dataFieldComponents";
import Input from "molecule/input/Input";
import { InputProps } from "atom/input/inputComponents";

function AutoFormInputField(
  componentProps: InputProps & { name: string; label?: string }
) {
  const [autoFormFieldProps] = useAutoFormField<DataFieldProps>(
    componentProps,
    componentProps.type,
    componentProps.ref
  );
  if (!autoFormFieldProps || !autoFormFieldProps.name) return null;

  return <Input {...componentProps} {...autoFormFieldProps} />;
}

export default AutoFormInputField;
