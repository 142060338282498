import { PaymentControllerService } from "@9amhealth/openapi";
import type {
  PaymentMethod,
  PaymentMethodCreateParams,
  Stripe,
  StripeElements
} from "@stripe/stripe-js";
import { Cubit } from "blac";
import appErrors from "src/constants/appErrors";
import reportErrorSentry from "src/lib/reportErrorSentry";
import translate from "src/lib/translate";
import { LoadingKey } from "src/state/LoadingCubit/LoadingCubit";
import ProfileCubit from "src/state/ProfileCubit/ProfileCubit";
import type { SubscriptionId } from "src/state/SubscriptionCubit/SubscriptionCubit";
import { UserPreferenceKeys } from "src/state/UserPreferencesCubit/UserPreferencesCubit";
import { loadingState, userPreferences, userState } from "src/state/state";

export class PaymentStateInitial {
  clientSecret?: string;
  error?: string = "";

  constructor(options: PaymentStateInitial = {}) {
    this.clientSecret = options.clientSecret;
    this.error = options.error;
  }
}

export class PaymentStateAuthorised extends PaymentStateInitial {}

export default class PaymentCubit extends Cubit<PaymentStateInitial> {
  private tryCount = 0;

  constructor() {
    super(new PaymentStateInitial());
  }

  public readonly initiatePaymentMethodCollection = async (): Promise<void> => {
    try {
      const { data } =
        await PaymentControllerService.initiatePaymentMethodCollection();
      this.emit(
        new PaymentStateInitial({
          clientSecret: data.parameters?.clientSecret
        })
      );
    } catch (e: unknown) {
      reportErrorSentry(e);
    }
  };

  readonly resetIntent = (): void => {
    this.emit(
      new PaymentStateInitial({
        clientSecret: undefined
      })
    );
  };

  // method for checking if confirmed card is set on the backend as default payment method
  // added to bypass race condition on BE
  // More info: https://9amhealth.atlassian.net/browse/FRONT-236?focusedCommentId=10250
  public readonly getAndWaitForPaymentMethod = async (
    paymentId: PaymentMethod | string
  ): Promise<boolean | undefined> => {
    // retries required to wait until BE sets confirmed card as a default payment method
    // https://9amhealth.atlassian.net/browse/FRONT-236?focusedCommentId=10250
    if (this.tryCount > 10) return false;
    this.tryCount = this.tryCount + 1;

    const {
      data: { paymentMethods }
    } = await PaymentControllerService.getPaymentMethods();

    const isDefaultPaymentMethod = paymentMethods.find(
      (payment) => payment.id === paymentId
    )?.default;

    if (isDefaultPaymentMethod) return true;

    return this.getAndWaitForPaymentMethod(paymentId);
  };

  public readonly confirmSetupPaymentMethod = async (
    elements: StripeElements | null,
    stripe: Stripe | null
  ): Promise<boolean> => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      const e = new Error("Stripe.js has not loaded");
      reportErrorSentry(e);
      return false;
    }
    this.emit(new PaymentStateInitial({ ...this.state, error: undefined }));
    loadingState.start(LoadingKey.PAYMENT_METHOD_SETUP);
    let success = false;

    const billingDetails: PaymentMethodCreateParams.BillingDetails = {};

    try {
      const userPref = await userPreferences.loadUserPreferences();
      const phoneNumber = await ProfileCubit.getPhoneNumber();

      if (!userState.state.userData?.email) {
        await userState.loadUserInfo();
      }

      if (userPref) {
        billingDetails.email = userState.state.userData?.email;
        billingDetails.name = `${userPref[UserPreferenceKeys.shipmentAddressFirstName]} ${
          userPref[UserPreferenceKeys.shipmentAddressLastName]
        }`;
        billingDetails.phone = phoneNumber?.number;
        billingDetails.address = {
          line1: userPref[UserPreferenceKeys.shipmentAddressStreet] ?? "",
          line2: userPref[UserPreferenceKeys.shipmentAddressAptSuite] ?? "",
          city: userPref[UserPreferenceKeys.shipmentAddressCity] ?? "",
          state: userPref[UserPreferenceKeys.shipmentAddressState] ?? "",
          postal_code: userPref[UserPreferenceKeys.shipmentAddressZip] ?? "",
          country: "US"
        };
      }
    } catch (error: unknown) {
      reportErrorSentry(error);
    }

    try {
      const response = await stripe.confirmSetup({
        //`Elements` instance that was used to create the Payment Element
        elements,
        redirect: "if_required",
        confirmParams: {
          payment_method_data: {
            billing_details: billingDetails
          }
        }
      });

      if (response.error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        reportErrorSentry(response.error);
        this.emit(
          new PaymentStateInitial({
            ...this.state,
            error: response.error.message
          })
        );
      } else {
        // In this point, the setup has been confirmed.
        // We have to wait for the payment method to be set as default on the backend, by
        // pooling the BE for a while. Without this, there is a possibility that
        // previous payment method will be used for purchase.
        if (!response.setupIntent.payment_method)
          throw new Error("No payment method in setup intent response");

        const isPaymentMethodSetAsDefault =
          await this.getAndWaitForPaymentMethod(
            response.setupIntent.payment_method
          );

        if (isPaymentMethodSetAsDefault) {
          // Your customer will be redirected to your `return_url`. For some payment
          // methods like iDEAL, your customer will be redirected to an intermediate
          // site first to authorize the payment, then redirected to the `return_url`.

          success = true;
        } else {
          this.emit(
            new PaymentStateInitial({
              ...this.state,
              error: translate(appErrors.default_payment_retry_count_exceeded)
            })
          );
        }
      }
    } catch (error) {
      reportErrorSentry(error);
      this.emit(
        new PaymentStateInitial({
          ...this.state,
          error:
            "There was an error processing your payment. Please try again. Or contact support."
        })
      );
    }

    loadingState.finish(LoadingKey.PAYMENT_METHOD_SETUP);
    return success;
  };

  public readonly purchase = async (
    subscriptionId: SubscriptionId,
    elements: StripeElements | null,
    stripe: Stripe | null,
    buyAction: (
      subscriptionId: SubscriptionId,
      options?: { onError?: () => void; onSuccess?: () => void },
      scopeKey?: string
    ) => Promise<boolean>,
    useDefaultPayment = false,
    scopeKey?: string,
    onComplete?: () => void,
    onError?: () => void
  ): Promise<void> => {
    if (useDefaultPayment) {
      await buyAction(
        subscriptionId,
        {
          onSuccess: onComplete
        },
        scopeKey
      );
    } else {
      await this.confirmSetupPaymentMethod(elements, stripe).then(
        async (success) => {
          if (success) {
            await buyAction(
              subscriptionId,
              {
                onError: () => {
                  this.resetIntent();
                  onError?.();
                },
                onSuccess: onComplete
              },
              scopeKey
            );
          } else {
            onError?.();
          }
        }
      );
    }
  };

  public readonly setError = (error?: string): void => {
    this.emit(
      new PaymentStateInitial({
        ...this.state,
        error
      })
    );
  };
}
