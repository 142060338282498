/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Updates an appointment.
 */
export type UpdateAppointmentRequest = {
    /**
     * The new appointment status.
     */
    status?: UpdateAppointmentRequest.status;
};

export namespace UpdateAppointmentRequest {

    /**
     * The new appointment status.
     */
    export enum status {
        SCHEDULED = 'SCHEDULED',
        CANCELED = 'CANCELED',
        NO_SHOW = 'NO_SHOW',
        COMPLETED = 'COMPLETED',
    }


}

