import { App } from "@capacitor/app";
import { Cubit } from "blac";
import {
  AuthorizationQueryOptions,
  Health,
  HealthAuthStatusResponse,
  HealthDataDay,
  HealthDataType
} from "cap-healthkit-healthconnect";
import { LoincCodingCode } from "src/constants/fhir";
import reportErrorSentry from "src/lib/reportErrorSentry";
import LabResultsCubit from "../LabResultsCubit/LabResultsCubit";
import { authenticationState, toast, tracker } from "../state";
import { Device } from "fhir/r4";
import Fhir from "src/lib/fhir";
import { StorageController } from "../StorageBloc/StorageBloc";
import {
  DeviceControllerService,
  RegisterDeviceRequest
} from "@9amhealth/openapi";
import { TrackEvent } from "../Track/TrackCubit";
import { Blac } from "blac-next";
import { logSentryBreadcrumb } from "src/lib/addSentryBreadcrumb";

interface HealthSyncState {
  available: boolean;
  stepsAuthorized?: HealthAuthStatusResponse["status"];
  stepsData: HealthDataDay[];
  lastImported?: Date;
}

export default class HealthSyncBloc extends Cubit<HealthSyncState> {
  device?: Device;
  devicdRegisteredStorageKey = "deviceRegistered";
  deviceRegistered = false;

  constructor() {
    super({
      available: false,
      stepsData: []
    });
    this.addAppListeners();
    void this.checkHealthAvailability();
  }

  get isAvailable(): boolean {
    return this.state.available;
  }
  get stepsAuthorized(): boolean {
    return this.state.stepsAuthorized === "granted";
  }

  verboseLogging = false;
  log = (...args: unknown[]) => {
    if (this.verboseLogging) {
      // eslint-disable-next-line no-console
      console.log("HealthSyncBloc", ...args);
    }
    logSentryBreadcrumb("healthSync", ...args);
  };

  checkAndSync = async () => {
    this.checkDeviceRegistered();
    await this.checkHealthAvailability();
    if (this.state.available) {
      try {
        await Promise.all([
          this.getDeviceData(),
          this.checkPermissionsSteps(),
          this.importHealthDataFromDevice()
        ]);
        await this.saveHealthDataToDatabase();
      } catch (error) {
        reportErrorSentry(error);
      }
    }
  };

  addAppListeners = () => {
    void App.addListener("appStateChange", (data) => {
      if (authenticationState.accessToken && data.isActive) {
        void this.checkAndSync();
      }
    });
  };

  checkDeviceRegistered = () => {
    const deviceRegistered =
      StorageController.getItem(this.devicdRegisteredStorageKey) === "true";
    this.log("Device registered", deviceRegistered);
    this.deviceRegistered = deviceRegistered;
  };

  registerDevice = async () => {
    if (this.deviceRegistered) {
      this.log("device already registered");
      return;
    }
    if (this.state.stepsAuthorized !== "granted") {
      this.log("not registering device: health authorization not granted");
      return;
    }
    if (!this.device) {
      reportErrorSentry(new Error("No device"));
      await this.getDeviceData();
    }
    if (!this.device || !this.device.id) {
      reportErrorSentry(new Error("No device id"));
      this.log("no device id");
      return;
    }

    const model: RegisterDeviceRequest["model"] =
      this.device.manufacturer === "Apple Inc."
        ? RegisterDeviceRequest.model.OTHER_IPHONE
        : RegisterDeviceRequest.model.OTHER_ANDROID_PHONE;

    try {
      this.log("register device now", this.device.id);
      await DeviceControllerService.registerDeviceByExternalId({
        externalDeviceId: this.device.id,
        type: RegisterDeviceRequest.type.MOBILE_PHONE,
        model,
        modelName: this.device.modelNumber
      });
      tracker.track("Device Registered" as TrackEvent, {
        data: {
          deviceId: this.device.id,
          model: this.device.modelNumber,
          manufacturer: this.device.manufacturer
        }
      });
    } catch (e) {
      reportErrorSentry(e);
    }
    StorageController.setItem(this.devicdRegisteredStorageKey, "true");
  };

  unregisterDevice = async () => {
    if (!this.deviceRegistered) {
      this.log("device not registered");
      return;
    }
    StorageController.setItem(this.devicdRegisteredStorageKey, "false");
    if (!this.device || !this.device.id) {
      reportErrorSentry(new Error("No device id"));
      return;
    }
    try {
      this.log("deregister device now", this.device.id);
      await DeviceControllerService.deregisterDeviceByExternalId({
        externalDeviceId: this.device.id
      });
      tracker.track("Device Deregistered" as TrackEvent, {
        data: {
          deviceId: this.device.id,
          model: this.device.modelNumber,
          manufacturer: this.device.manufacturer
        }
      });
    } catch (e) {
      reportErrorSentry(e);
    }
  };

  getDeviceData = async () => {
    if (this.device) {
      this.log("device already registered");
      return;
    }
    try {
      const deviceData = await Health.getDeviceInfo();
      this.log("device data", deviceData);
      if (!deviceData.deviceInformation.identifierForVendor) {
        reportErrorSentry("No device identifier");
        return;
      }
      this.device = Fhir.createDevice({
        id: deviceData.deviceInformation.identifierForVendor,
        modelNumber: deviceData.deviceInformation.hardwareVersion,
        manufacturer: deviceData.deviceInformation.manufacturer,
        coding: "activity-tracker"
      });
    } catch (error) {
      reportErrorSentry(error);
    }
  };

  checkPermissionsSteps = async () => {
    this.log("check permissions steps");
    const typeChecked = HealthDataType.STEPS;

    try {
      const authorized = await Health.checkAuthStatus({
        read: [typeChecked]
      });
      this.log("authorized", authorized);
      this.emit({
        ...this.state,
        stepsAuthorized: authorized.status
      });
    } catch (error) {
      reportErrorSentry(error);
    }
  };

  checkHealthAvailability = async () => {
    try {
      const { availability } = await Health.isHealthDataAvailable();
      this.emit({ ...this.state, available: availability === "Available" });
      this.log("health availability", this.state.available);
    } catch (error) {
      reportErrorSentry(error);
    }
  };

  requestAuthorization = async () => {
    const authTypes: HealthDataType[] = [HealthDataType.STEPS];
    const params: AuthorizationQueryOptions = {
      read: authTypes
    };

    this.log("request authorization", params);
    const statusBeforeRequest = this.state.stepsAuthorized;
    if (statusBeforeRequest !== "granted") {
      tracker.track("Health Authorization Requested" as TrackEvent, {
        data: {
          authTypes,
          status: statusBeforeRequest
        }
      });
    }

    try {
      await Health.requestAuthorization(params);
      await this.checkPermissionsSteps();
      if (this.state.stepsAuthorized !== "granted") {
        this.log("health authorization failed");
        throw new Error("Health authorization failed");
      }

      if (this.state.stepsAuthorized === "granted") {
        StorageController.setItem("healthActive", "true");
      }
      if (
        this.state.stepsAuthorized === "granted" &&
        statusBeforeRequest !== "granted"
      ) {
        tracker.track("Health Authorization Granted" as TrackEvent, {
          data: {
            authTypes,
            latest: "granted"
          }
        });
      }
      await this.checkAndSync();
    } catch (error) {
      this.log("health authorization failed", error);
      toast.error("health.authorizationFailed", { duration: 0 });
    }
  };

  importHealthDataFromDevice = async () => {
    this.log("import health data from device");
    await this.checkPermissionsSteps();
    if (this.state.stepsAuthorized !== "granted") {
      this.log("health authorization not granted");
      return;
    }

    const oneDay = 24 * 60 * 60 * 1000;
    const dateTo = new Date(Date.now() - oneDay).toISOString();

    const daysLookBack = 90;

    const dateFrom = new Date(Date.now() - daysLookBack * oneDay).toISOString();

    try {
      this.log("get steps for days", dateFrom, dateTo);
      const stepsData = await Health.getStepsForDays({
        from: dateFrom.split("T")[0],
        to: dateTo.split("T")[0]
      });
      this.log("steps data", stepsData);
      const steps = stepsData.stepsByDay.toReversed();
      const onlyWithValues = steps.filter((step) => step.value > 0);
      this.emit({
        ...this.state,
        stepsData: onlyWithValues,
        lastImported: new Date()
      });
    } catch (e) {
      reportErrorSentry(e);
    }
  };

  newestStepDate: string | undefined;
  saveHealthDataToDatabase = async () => {
    if (this.state.stepsAuthorized !== "granted") {
      this.log("not saving health data: health authorization not granted");
      return;
    }
    await this.registerDevice();
    // load steps observations from backend
    const labResultsState = Blac.getBloc(LabResultsCubit);
    await labResultsState.loadObservations();
    const observations = labResultsState.state.observations?.filter((obs) => {
      return obs.code.coding?.[0].code === LoincCodingCode.stepsInDay;
    });

    // collect steps from healthkit that are not in the backend yet, check the date
    const stepsNotInBackend = this.state.stepsData.filter((step) => {
      const observation = observations?.find((obs) => {
        return (
          obs.effectiveDateTime?.split("T")[0] === step.startDate.split("T")[0]
        );
      });
      return !observation;
    });

    // create steps observations and save them to the backend
    const stepsObservations = stepsNotInBackend.map((step) =>
      LabResultsCubit.createObservationDailySteps(step.value, {
        date: step.startDate,
        device: this.device
          ? {
              reference: `#${this.device.id}`
            }
          : undefined,
        contained: this.device ? [this.device] : undefined
      })
    );

    this.log("steps observations", stepsObservations);

    // save steps to backend
    if (stepsObservations.length > 0) {
      // eslint-disable-next-line @typescript-eslint/prefer-destructuring
      const newestStepDate = stepsNotInBackend[0].startDate.split("T")[0];
      if (this.newestStepDate === newestStepDate) {
        this.log("newest step date not changed");
        return;
      }
      this.newestStepDate = newestStepDate;
      const error = await LabResultsCubit.storeConnectedDeviceLabValues(
        stepsObservations,
        "apple-health"
      );
      if (!error) {
        tracker.track("Health Data imported from device" as TrackEvent);
      }
    }
  };

  runBgTask = async () => {
    this.log("run bg task");
    await Health.runBackgroundTaskHandler();
  };

  get isDataAvailableSteps(): boolean {
    return (
      this.state.stepsAuthorized === "granted" &&
      this.state.stepsData.length > 0
    );
  }
}
