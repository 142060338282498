import { css } from "@emotion/css";
import styled from "@emotion/styled";
import type { FC } from "react";
import React, { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import InDialog from "src/ui/components/InDialog/InDialog";
import useGoToOrBack from "src/ui/hooks/useGoToOrBack";
import { AppQueryPopupsController } from "../AppQueryPopupsBloc";
import { isAperoLink, isBlogLink } from "lib/Urls";
import clsx from "clsx";

export const ionModalAperoStyles = css`
  ion-content {
    --dialog-background: rgb(245, 244, 248);
    &::part(scroll) {
      overflow: auto;
      padding: 0;
    }
  }

  @media (pointer: coarse) {
    ion-content {
      iframe {
        margin: 0 0 40vh;
      }
    }
  }

  @media (prefers-color-scheme: dark) {
    ion-content {
      --dialog-background: rgb(19, 18, 22);
    }
  }
`;

export const ionModalStyles = css`
  ion-content {
    &::part(scroll) {
      overflow: hidden;
      padding: 0;
    }
    iframe {
      display: block;
      width: 100%;
      height: 100%;
      border: none;
    }
  }
`;

const DetailFrame = styled.iframe`
  outline: none;
  border: none;
  width: 100%;
  height: 100%;
  margin: 0;
  padding-bottom: 0 !important;
`;

const IframePopup: FC<{
  returnUrl: string;
}> = ({ returnUrl }) => {
  const navigate = useGoToOrBack();

  const [query] = useSearchParams();
  const properUrl = decodeURIComponent(query.get("url") ?? "");
  const title = query.get("title") ?? "";
  const isArticle = query.get("popup") === "article";
  const isAperoUrl = isAperoLink(properUrl);

  const useUrl = useMemo(() => {
    if (!properUrl) return "";
    const urlObj = new URL(properUrl);
    if (isBlogLink(properUrl)) {
      // add query param co=1 for content only view
      urlObj.searchParams.append("co", "1");
    }
    return urlObj.toString();
  }, [properUrl]);

  const onClose = () => {
    AppQueryPopupsController.closePopup();
    navigate(returnUrl, {
      multiBack: true,
      replace: true
    });
  };

  return (
    <InDialog
      popup
      title={title}
      onClose={onClose}
      returnUrl={returnUrl}
      className={clsx(ionModalStyles, {
        [ionModalAperoStyles]: isAperoUrl
      })}
      isFullScreen={isArticle}
    >
      {useUrl && <DetailFrame src={useUrl} />}
    </InDialog>
  );
};

export default IframePopup;
