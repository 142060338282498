import type { InputChangeDetails, NineInput } from "@9amhealth/wcl";
import type { FC, ReactElement } from "react";
import React, { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import {
  formatPhoneNumberNational,
  validatePhoneNumber
} from "src/lib/formatPhoneNumber";
import translate from "src/lib/translate";
import ProfileCubit from "src/state/ProfileCubit/ProfileCubit";
import { userState } from "src/state/state";
import type { FormInputProps } from "src/ui/components/FormInput/FormInput";
import OnEvent from "src/ui/components/OnEvent/OnEvent";

interface FormPhoneInputProps extends FormInputProps {
  onNumberChange?: (value: string) => void;
}

const PhoneNumberInput: FC<FormPhoneInputProps> = (props): ReactElement => {
  const { name, label, pattern, required, onNumberChange } = props;
  const [val, setVal] = useState<string>("");
  const [numberError, setNumberError] = useState<string>("");
  const inputRef = React.useRef<NineInput>(null);

  const labelText = label ? translate(label) : "";
  const { register, setValue, getValues } = useFormContext();

  const registerProps = register(name, {
    required,
    pattern: pattern as undefined
  });

  const handleBlur = useCallback(
    (event: CustomEvent<InputChangeDetails>) => {
      const originalFn = event.detail.originalEvent;
      if (!originalFn) return;

      props.onBlur?.(event);
      void registerProps.onBlur(originalFn as unknown as FocusEvent);
    },
    [name]
  );

  const handleChange = useCallback((event: CustomEvent<InputChangeDetails>) => {
    setValue(name, event.detail.value);
    const value = formatPhoneNumberNational(event.detail.value) ?? "";
    const maskFilled = event.detail.maskFilled ?? false;

    setNumberError("");
    if (!maskFilled) {
      return;
    }

    try {
      const numberValid = validatePhoneNumber(value, "US");
      if (value && numberValid) {
        onNumberChange?.(value);
      } else {
        onNumberChange?.("");
        setNumberError(translate("error_phone_number_invalid"));
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e: unknown) {
      onNumberChange?.("");
      setNumberError(translate("error_phone_number_invalid"));
    }
  }, []);

  useEffect(() => {
    // Initiate value
    const value = getValues()[name] as string | undefined;
    if (value) {
      setVal(formatPhoneNumberNational(value) ?? "");
    } else {
      // do not load phone number if user is temp user
      if (userState.isTempUser) return;

      // if there is no value, try to load the users default number
      void ProfileCubit.getPhoneNumber().then((number) => {
        if (number) {
          const numberValid = validatePhoneNumber(number.number, "US");
          if (numberValid) {
            setVal(formatPhoneNumberNational(number.number) ?? "");
            handleChange(
              new CustomEvent("change", {
                detail: {
                  value: number.number,
                  name,
                  required: true,
                  maskFilled: true
                }
              })
            );
          }
        }
      });
    }
  }, []);

  return (
    <OnEvent
      events={{
        nineInputChange: handleChange,
        nineInputBlur: handleBlur
      }}
    >
      <nine-input
        scroll-into-view-on-focus
        ref={inputRef}
        name={name}
        label={labelText}
        required={required ? "true" : "false"}
        error={numberError}
        error-controlled
        value={val}
        key={val}
        mask="(000) 000-0000"
        lazy="false"
        type="tel"
      ></nine-input>
    </OnEvent>
  );
};

export default PhoneNumberInput;
