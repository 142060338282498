import { Cubit } from "blac-next";

type ConfirmingAppointmentInfoState = {
  showConfirmInfo: boolean;
};

export default class ConfirmingAppointmentInfoBloc extends Cubit<ConfirmingAppointmentInfoState> {
  constructor() {
    super({
      showConfirmInfo: false
    });
  }

  setShowConfirmInfo = (showConfirmInfo: boolean): void => {
    this.emit({ ...this.state, showConfirmInfo });
  };
}
