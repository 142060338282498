import type { FC } from "react";
import React from "react";
import type { TrackEvent } from "src/state/Track/TrackCubit";
import { tracker } from "src/state/state";
import InDialog from "src/ui/components/InDialog/InDialog";
import useGoToOrBack from "src/ui/hooks/useGoToOrBack";
import { AppQueryPopupsController } from "../AppQueryPopupsBloc";
import MultiStepPharmacyInsuranceForm from "../../MultiStepPharmacyInsuranceForm/MultiStepPharmacyInsuranceForm";
import translate from "src/lib/translate";
import { Blac } from "blac-next";
import PatientPharmacyInsuranceBloc from "src/state/PatientPharmacyInsuranceBloc/PatientPharmacyInsuranceBloc";

const EnterPharmacyInsurance: FC<{
  returnUrl: string;
}> = ({ returnUrl }) => {
  const navigate = useGoToOrBack();

  const onClose = () => {
    AppQueryPopupsController.closePopup();
    navigate(returnUrl, {
      multiBack: true,
      replace: true
    });
  };

  const handleSaved = () => {
    tracker.track("Pharmacy Insurance Card Added/Updated" as TrackEvent);
    AppQueryPopupsController.closePopup();
    void Blac.getBloc(PatientPharmacyInsuranceBloc).loadInsurances();
  };

  return (
    <InDialog
      popup
      title={translate("pharmacyInsuranceCard")}
      onClose={onClose}
      returnUrl={returnUrl}
    >
      <MultiStepPharmacyInsuranceForm
        onInsuranceSaved={handleSaved}
        onCanceled={onClose}
      />
    </InDialog>
  );
};

export default EnterPharmacyInsurance;
