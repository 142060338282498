import styled from "@emotion/styled";
import type { FC } from "react";
import React from "react";
import { icons, type Rating } from "src/ui/components/Feedback/Feedback";
import Translate from "src/ui/components/Translate/Translate";

const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: flex-start;
  padding: 1em 1em 0.8em;
  border-radius: 0.5rem;
  background-color: white;
  border: 1px solid var(--color-gray-light);
  position: relative;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 2rem;
  min-height: 2rem;
  border-radius: 0.5rem;
  --border-color: #6fc696;
  --bg-color: var(--color-afternoon-green);

  border: 1px solid var(--border-color);
  background-color: var(--bg-color);

  &[data-rating="good"] {
    --border-color: #6fc696;
    --bg-color: var(--color-afternoon-green);
  }

  &[data-rating="okay"] {
    --border-color: var(--color-sunrise-orange);
    --bg-color: var(--color-afternoon-yellow);
  }

  &[data-rating="bad"] {
    --border-color: #ff7663;
    --bg-color: #ffd4c8;
  }

  svg {
    width: 1rem;
    height: 1rem;
  }
`;

const RatingContent = styled.div`
  border-left: 2px solid var(--color-gray-fog);
  padding-left: calc(var(--sm-scale) * 0.75);
  margin-top: calc(var(--sm-scale) * 0.5);
`;

const Content = styled.div`
  margin-top: calc(var(--sm-scale) * 0.5);
`;

const UserFeedback: FC<{
  rating?: Rating;
  ratingGrade?: number;
  maxRatingGrade?: number;
  userFeedback?: string;
}> = ({ rating = "good", ratingGrade, userFeedback, maxRatingGrade = 10 }) => {
  const Icon = icons[rating];

  return (
    <Wrapper>
      <IconWrapper data-rating={rating}>
        <Icon />
      </IconWrapper>

      <Content>
        <p className="strong m0">
          <Translate msg={`feedback_${rating}`} />
          {ratingGrade && (
            <span>
              : {ratingGrade}/{maxRatingGrade}
            </span>
          )}
        </p>
        {userFeedback && (
          <RatingContent>
            <p className="as-little">{userFeedback}</p>
          </RatingContent>
        )}
      </Content>
    </Wrapper>
  );
};

export default UserFeedback;
