import styled from "@emotion/styled";
import type { FC } from "react";
import React from "react";
import { APP_BREAKPOINT } from "src/constants/layout";
import translate from "src/lib/translate";
import BBBLogo from "src/ui/assets/logos/bbb_logo_new.png";
import Translate from "src/ui/components/Translate/Translate";

//#region Styled components
const TrustWrap = styled.div`
  label: TrustWrap;
  display: grid;
  place-items: center;
  gap: 1.5rem;
  margin-bottom: 5rem;
  text-align: center;

  small {
    color: var(--color-charcoal-60);
    font-size: 0.75rem;
  }

  @media screen and (min-width: ${APP_BREAKPOINT}px) {
    gap: 2rem;
  }
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const StyledLogo = styled.img`
  display: block;
  height: 2.5rem;
`;
//#endregion

export const Trust: FC = () => {
  return (
    <TrustWrap>
      <StyledRow>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
        >
          <path
            fill="#212121"
            fillOpacity=".6"
            fillRule="evenodd"
            d="M6.5 8a5.5 5.5 0 1 1 11 0v1.572c.407.052.761.142 1.089.31a3.5 3.5 0 0 1 1.53 1.529c.205.403.295.847.338 1.378.043.52.043 1.168.043 1.989v1.444c0 .822 0 1.468-.043 1.99-.043.53-.133.974-.338 1.377a3.5 3.5 0 0 1-1.53 1.53c-.403.205-.847.295-1.378.338-.52.043-1.167.043-1.989.043H8.778c-.821 0-1.468 0-1.99-.043-.53-.043-.974-.133-1.377-.338a3.5 3.5 0 0 1-1.53-1.53c-.205-.403-.295-.847-.338-1.378-.043-.52-.043-1.167-.043-1.989v-1.444c0-.822 0-1.469.043-1.99.043-.53.133-.974.338-1.377a3.5 3.5 0 0 1 1.53-1.53c.328-.167.682-.257 1.089-.309V8Zm1 1.509c.372-.009.795-.009 1.278-.009h6.444c.483 0 .906 0 1.278.009V8a4.5 4.5 0 1 0-9 0v1.509Zm-.63 1.03c-.469.039-.766.112-1.005.234a2.5 2.5 0 0 0-1.093 1.092c-.121.239-.194.536-.233 1.005-.039.476-.039 1.082-.039 1.93v1.4c0 .848 0 1.454.04 1.93.038.469.11.766.232 1.005a2.5 2.5 0 0 0 1.093 1.092c.239.122.536.195 1.005.234.475.039 1.082.039 1.93.039h6.4c.848 0 1.454 0 1.93-.04.469-.038.766-.11 1.005-.233a2.5 2.5 0 0 0 1.092-1.092c.122-.239.195-.536.234-1.005.039-.476.039-1.082.039-1.93v-1.4c0-.848 0-1.455-.04-1.93-.038-.469-.11-.766-.233-1.005a2.5 2.5 0 0 0-1.092-1.092c-.239-.122-.536-.195-1.005-.234-.476-.039-1.082-.039-1.93-.039H8.8c-.848 0-1.455 0-1.93.04ZM12 14a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2a.5.5 0 0 1 .5-.5Z"
            clipRule="evenodd"
          />
        </svg>
        <small>
          <Translate msg="trust.header" noWrap />
        </small>
      </StyledRow>
      <div>
        <a
          href="https://www.bbb.org/us/ca/encinitas/profile/health-and-wellness/9amhealth-1126-1000089320/#sealclick%22"
          target="_blank"
          rel="nofollow norefferer"
        >
          <StyledLogo src={BBBLogo} alt={translate("trust.logo.alt")} />
        </a>
      </div>
      <div>
        <small>
          <Translate msg="trust.footer" noWrap />
        </small>
      </div>
    </TrustWrap>
  );
};
