import { App } from "@capacitor/app";
import { Cubit } from "blac";
import type {
  AvailableResult,
  Credentials
} from "@capgo/capacitor-native-biometric";
import { NativeBiometric } from "@capgo/capacitor-native-biometric";
import { globalEvents } from "src/constants/globalEvents";
import { isHybridApp } from "src/lib/platform";
import reportErrorSentry from "src/lib/reportErrorSentry";
import translate from "src/lib/translate";
import { TrackEvent } from "src/state/Track/TrackCubit";
import { toast, tracker } from "src/state/state";
import type { TranslationKey } from "src/types/translationKey";
import BlockingLoadingOverlayController from "src/ui/components/BlockingLoadingOverlay/BlockingLoadingOverlayController";
import { FeatureFlagName, featureFlags } from "src/lib/featureFlags";
import { logSentryBreadcrumb } from "src/lib/addSentryBreadcrumb";

enum BiometryType {
  NONE = 0,
  TOUCH_ID = 1,
  FACE_ID = 2,
  FINGERPRINT = 3,
  FACE_AUTHENTICATION = 4,
  IRIS_AUTHENTICATION = 5,
  MULTIPLE = 6
}

export interface BiometricVerificationState {
  isBiometricVerificationEnabled: boolean;
  isBiometricVerificationAvailable: boolean;
  showSetupPrompt: boolean;
  biometricType: BiometryType;
  credentialsAvailable?: boolean;
}

export class BiometricVerificationBloc extends Cubit<BiometricVerificationState> {
  server: string | null = null;
  enabled = isHybridApp();

  log = (...args: unknown[]): void => {
    if (featureFlags.getFlag(FeatureFlagName.loggingBiometricsVerification)) {
      // eslint-disable-next-line no-console
      console.warn("BioVeri", ...args);
    }
    logSentryBreadcrumb("biometricsVerification", ...args);
  };

  promptText: Record<
    BiometryType,
    {
      reason?: TranslationKey;
      title?: TranslationKey;
      description?: TranslationKey;
      negativeButtonText?: TranslationKey;
    }
  > = {
    [BiometryType.FACE_ID]: {
      title: "signIn.with.faceId.title",
      reason: "signIn.with.faceId.title",
      description: "signIn.with.faceId.description"
    },
    [BiometryType.TOUCH_ID]: {
      title: "signIn.with.touchId.title",
      reason: "signIn.with.touchId.title",
      description: "signIn.with.touchId.description"
    },
    [BiometryType.FINGERPRINT]: {},
    [BiometryType.FACE_AUTHENTICATION]: {},
    [BiometryType.IRIS_AUTHENTICATION]: {},
    [BiometryType.MULTIPLE]: {},
    [BiometryType.NONE]: {}
  };

  get biometricTypeName(): string | undefined {
    const type = this.state.biometricType;
    switch (type) {
      case BiometryType.FACE_ID:
        return "faceId";
      case BiometryType.TOUCH_ID:
        return "touchId";
      case BiometryType.FINGERPRINT:
        return "fingerprint";
      case BiometryType.FACE_AUTHENTICATION:
        return "faceAuthentication";
      case BiometryType.IRIS_AUTHENTICATION:
        return "iris";
      case BiometryType.MULTIPLE:
        return undefined;
      default:
        return undefined;
    }
  }

  constructor() {
    super({
      isBiometricVerificationEnabled: false,
      isBiometricVerificationAvailable: false,
      showSetupPrompt: false,
      biometricType: 0
    });

    void this.init();
    window.addEventListener(globalEvents.USER_CLEAR, () => {
      void this.disable();
    });
  }

  async init(): Promise<void> {
    if (!this.enabled) return;
    await this.getServer();
    await this.checkSupport();
  }

  async getServer(): Promise<string | null> {
    if (!this.enabled) return null;
    const info = await App.getInfo();
    return info.id;
  }

  isAvailableResult: AvailableResult | undefined;
  async isAvailable(): Promise<AvailableResult> {
    if (this.isAvailableResult) {
      return this.isAvailableResult;
    }

    this.isAvailableResult = await NativeBiometric.isAvailable();
    return this.isAvailableResult;
  }

  checkSupport = async (): Promise<boolean> => {
    if (!this.enabled) {
      this.emit({
        ...this.state,
        isBiometricVerificationAvailable: false
      });
      return false;
    }
    const server = await this.getServer();
    if (!server) {
      reportErrorSentry(new Error("Credential server is not set"));
      return false;
    }

    const result = await this.isAvailable();

    this.log("isavailable", result);
    if (result.isAvailable) {
      let credentialsAvailable = false;
      try {
        const credentials = await NativeBiometric.getCredentials({
          server
        });
        this.log("credentials", credentials);
        if (credentials.username && credentials.password) {
          credentialsAvailable = true;
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (_) {
        // ignore
      }

      const cachedEnabled = localStorage.getItem("biometricEnabled") === "true";
      this.emit({
        ...this.state,
        isBiometricVerificationAvailable: result.isAvailable,
        isBiometricVerificationEnabled: cachedEnabled,
        biometricType: result.biometryType,
        credentialsAvailable
      });
      this.log("state", this.state);
    }

    return result.isAvailable;
  };

  sessionVerified = false;
  isVerifying = false;
  verificationFailed = false;
  /**
   * Shows biometric prompt to verify the user's identity using biometric verification.
   */
  readonly verifyIdentity = async (): Promise<void> => {
    if (!this.enabled || this.isVerifying) return;

    const supported = await this.checkSupport();
    if (!supported) {
      return;
    }

    // sync with session storage
    if (!this.sessionVerified) {
      const biometricVerified = sessionStorage.getItem("biometricVerified");
      if (biometricVerified === "true") {
        this.sessionVerified = true;
      }
    }

    if (this.verificationFailed) {
      this.verificationFailed = false;
      throw new Error("Biometric verification failed");
    }

    // if the session is already verified, return
    if (this.sessionVerified) {
      return;
    }

    this.isVerifying = true;
    let error: Error | undefined;

    tracker.track(TrackEvent.BioVerifyIdentity);

    const usePrompt = this.promptText[this.state.biometricType];

    const { reason, title, description } = usePrompt;

    BlockingLoadingOverlayController.startLoading({
      bg: "branded",
      fadeIn: false
    });

    try {
      await NativeBiometric.verifyIdentity({
        reason: reason ? translate(reason) : translate("signIn.biometrics"),
        title: title ? translate(title) : translate("signIn.biometrics"),
        description: description
          ? translate(description)
          : translate("biometric.description")
      });

      this.emit({
        ...this.state,
        isBiometricVerificationEnabled: true
      });
      localStorage.setItem("biometricEnabled", "true");

      sessionStorage.setItem("biometricVerified", "true");
      this.sessionVerified = true;
    } catch (e: unknown) {
      this.verificationFailed = true;
      toast.error("error_biometric_verification_failed");
      error = e as Error;
    }

    BlockingLoadingOverlayController.endLoading();
    this.isVerifying = false;

    if (error) {
      throw error;
    }
  };

  resetSessionVerified = (): void => {
    this.sessionVerified = false;
    sessionStorage.removeItem("biometricVerified");
  };

  tmpCredentials: Credentials | undefined;
  /**
   * Save the user's credentials in the device's keychain
   * @param credentials - The user's credentials
   */
  readonly setCredentials = async (credentials: Credentials): Promise<void> => {
    if (!this.enabled) return;
    await this.checkSupport();
    const server = await this.getServer();

    if (!server) {
      reportErrorSentry(new Error("Credential server is not set"));
      return;
    }
    this.log("setCredentials", { credentials, server });

    // show the biometric prompt if biometric verification is not enabled
    if (!this.state.isBiometricVerificationEnabled) {
      this.tmpCredentials = credentials;
      this.emit({
        ...this.state,
        showSetupPrompt: true
      });
      return;
    }

    tracker.track(TrackEvent.BioSetCredentials);

    try {
      await NativeBiometric.deleteCredentials({
        server
      });
      await NativeBiometric.setCredentials({
        username: credentials.username,
        password: credentials.password,
        server
      });

      this.emit({
        ...this.state,
        isBiometricVerificationEnabled: true,
        credentialsAvailable: true
      });
      localStorage.setItem("biometricEnabled", "true");
      localStorage.setItem("biometricCredentialsAvailable", "true");
    } catch (error) {
      reportErrorSentry(
        new Error("Failed to set biometric credentials", { cause: error })
      );
    }
  };

  /**
   * Get the user's credentials from the device's keychain
   */
  readonly getCredentials = async (): Promise<Credentials | undefined> => {
    if (!this.enabled || !this.state.isBiometricVerificationEnabled) {
      this.log("getCredentials", {
        enabled: this.enabled,
        isBiometricVerificationEnabled:
          this.state.isBiometricVerificationEnabled
      });
      return;
    }
    const server = await this.getServer();
    if (!server) {
      reportErrorSentry(new Error("Credential server is not set"));
      return;
    }
    await this.checkSupport();

    if (!this.state.credentialsAvailable) {
      this.log("getCredentials", {
        credentialsAvailable: this.state.credentialsAvailable
      });
      return undefined;
    }

    tracker.track(TrackEvent.BioGetCredentials);
    BlockingLoadingOverlayController.startLoading({
      bg: "branded"
    });

    try {
      await this.verifyIdentity();
    } catch (error) {
      reportErrorSentry(error);
      return undefined;
    } finally {
      BlockingLoadingOverlayController.endLoading();
    }

    try {
      const credentials = await NativeBiometric.getCredentials({
        server
      });

      this.emit({
        ...this.state,
        isBiometricVerificationEnabled: true
      });

      return credentials;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      toast.show("error_biometric_not_available");
      void this.deleteCredentials();
    }
  };

  /**
   * Delete the user's credentials from the device's keychain
   */
  readonly deleteCredentials = async (): Promise<void> => {
    await this.checkSupport();
    const server = await this.getServer();
    if (!server) {
      return;
    }
    try {
      await NativeBiometric.setCredentials({
        username: "",
        password: "",
        server
      });

      this.emit({
        ...this.state,
        credentialsAvailable: false,
        isBiometricVerificationEnabled: false
      });
      localStorage.removeItem("biometricCredentialsAvailable");
      localStorage.removeItem("biometricEnabled");
      this.resetSessionVerified();
    } catch (error) {
      reportErrorSentry(error);
    }
  };

  readonly setupBiometricVerification = async (): Promise<void> => {
    if (!this.enabled) return;
    this.resetSessionVerified();

    BlockingLoadingOverlayController.startLoading({
      bg: "branded"
    });
    try {
      await this.verifyIdentity();

      this.emit({
        ...this.state,
        isBiometricVerificationEnabled: true
      });
      localStorage.setItem("biometricEnabled", "true");

      if (this.tmpCredentials) {
        await this.setCredentials(this.tmpCredentials);
      }
    } catch (error) {
      reportErrorSentry(error);
    } finally {
      BlockingLoadingOverlayController.endLoading();
    }
  };

  readonly switchToFallbackCredentialProvider = async (): Promise<void> => {
    if (!this.enabled) return;

    try {
      await this.deleteCredentials();

      // disable biometric verification
      this.emit({
        ...this.state,
        isBiometricVerificationEnabled: false
      });
      localStorage.removeItem("biometricEnabled");

      toast.show("biometric.disabled");
    } catch (error) {
      reportErrorSentry(error);
    }
  };

  readonly openSetupPrompt = async (): Promise<void> => {
    if (!this.enabled) return;
    if (this.state.isBiometricVerificationEnabled) return;

    this.emit({
      ...this.state,
      showSetupPrompt: true
    });
  };

  readonly closeSetupPrompt = async (): Promise<void> => {
    if (!this.enabled) return;

    this.emit({
      ...this.state,
      showSetupPrompt: false
    });
  };

  readonly disable = async (): Promise<void> => {
    await this.deleteCredentials();
    this.sessionVerified = false;
    sessionStorage.removeItem("biometricVerified");
    this.emit({
      ...this.state,
      isBiometricVerificationEnabled: false
    });
    localStorage.removeItem("biometricEnabled");
  };
}
