/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Eligibility result metadata
 */
export type PartnerEligibilityResponse = {
    /**
     * Resulting status of the eligibility check
     */
    status: PartnerEligibilityResponse.status;
    /**
     * Internal ID of the partner for whom the check was ran
     */
    partnerId: string;
    /**
     * If the status is not ELIGIBLE, when know, the reasons will contained in this list
     */
    ineligibilityReasons?: Array<'SSO_USER_INELIGIBLE' | 'ELIGIBILITY_NOT_STARTED' | 'ELIGIBILITY_NOT_STARTED_DUE_TO_HIRE_DATE' | 'ELIGIBILITY_TERMINATED' | 'INVALID_DATA' | 'NOT_FOUND'>;
};

export namespace PartnerEligibilityResponse {

    /**
     * Resulting status of the eligibility check
     */
    export enum status {
        ELIGIBLE = 'ELIGIBLE',
        INELIGIBLE = 'INELIGIBLE',
        INFO_UNAVAILABLE = 'INFO_UNAVAILABLE',
        SKIPPED = 'SKIPPED',
        EXTERNAL_PROVIDER_ERROR = 'EXTERNAL_PROVIDER_ERROR',
    }


}

