import { SubscriptionDetailsResponse } from "@9amhealth/openapi";
import type { FC } from "react";
import React from "react";
import Path from "src/constants/path";
import { createAppPath } from "src/lib/createAppPath";
import translate from "src/lib/translate";
import SubscriptionCubit from "src/state/SubscriptionCubit/SubscriptionCubit";
import {
  ItemType,
  SubscriptionInterval
} from "src/state/TreatmentPlanCubit/TreatmentPlanCubit";
import UserCubit from "src/state/UserCubit/UserCubit";
import UserEmailVerificationCubit from "src/state/UserEmailVerificationCubit/UserEmailVerificationCubit";
import { toast, useBloc, userState } from "src/state/state";
import Prompt from "src/ui/components/Prompt/Prompt";
import status = SubscriptionDetailsResponse.status;

export const Prompts: FC = () => {
  const [, { filterAllSubscriptions, getSubscriptionPurchaseItems }] =
    useBloc(SubscriptionCubit);
  const [user] = useBloc(UserCubit);
  const [, { initiateVerification }] = useBloc(UserEmailVerificationCubit, {
    subscribe: false,
    create: () => new UserEmailVerificationCubit()
  });

  const resendVerificationEmailHandle = async (): Promise<void> => {
    await initiateVerification(user.userData?.email);

    toast.show("verify_email_prompt_notification");
  };

  const userHasEmail = Boolean(userState.state.userData?.email);

  const promptCanBeDisplayedOnCurrentPage = [
    Path.profile,
    Path.labResults,
    Path.myPlan,
    Path.chat
  ].some((path) => location.pathname.endsWith(createAppPath(path, "app")));

  const isSubscriptionPaused = Boolean(
    filterAllSubscriptions({
      status: [status.PAUSED],
      duration: [SubscriptionInterval.monthly, SubscriptionInterval.quarterly]
    }).length
  );
  const subscriptionContainsExpiredItems = Boolean(
    filterAllSubscriptions({
      status: [status.ACTIVE, status.IN_REVIEW]
    }).some((subscription) => {
      const items = getSubscriptionPurchaseItems(
        subscription.id,
        ItemType.MEDICATION_SUGGESTION
      );
      return items.some((item) => item.expiredAt);
    })
  );
  const subscriptionExpiredItemsPromptVisible =
    promptCanBeDisplayedOnCurrentPage && subscriptionContainsExpiredItems;

  const subscriptionPausedPromptVisible =
    promptCanBeDisplayedOnCurrentPage && isSubscriptionPaused;

  //show prompt on specific pages and when email is not verified in user properties
  const emailVerifyPromptVisible =
    promptCanBeDisplayedOnCurrentPage &&
    !subscriptionPausedPromptVisible &&
    !subscriptionExpiredItemsPromptVisible &&
    !user.userData?.emailVerified;

  return (
    <>
      <Prompt
        title={translate("verify_email_prompt_title")}
        message={user.userData?.email ?? ""}
        action={resendVerificationEmailHandle}
        visible={emailVerifyPromptVisible && userHasEmail}
        buttonText={translate("verify_email_prompt_button")}
      />

      <Prompt
        title={translate("subscription_paused_prompt_title")}
        visible={subscriptionPausedPromptVisible}
        textCenter
      />

      <Prompt
        title={translate("subscription_expired_items_prompt_title")}
        visible={subscriptionExpiredItemsPromptVisible}
        textCenter
        dangerBackground
      />
    </>
  );
};
