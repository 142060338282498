import type { FC } from "react";
import React from "react";

import { questionnaireFieldValidation } from "src/state/QuestionnaireCubit/QuestionnaireCubit";
import Loader from "src/ui/components/Loader/Loader";
import Translate from "src/ui/components/Translate/Translate";
import { z } from "zod";

import {
  AutoForm,
  AutoFormDataField,
  AutoFormDataFieldDate,
  Button,
  DataFieldGroup,
  pickerCurrentTime,
  useAutoFormControls
} from "@9amhealth/shared";
import { Blac } from "blac-next";
import { IconLabsBloodGlucoseDropWithSugarCube } from "src/constants/icons";
import reportErrorSentry from "src/lib/reportErrorSentry";
import translate from "src/lib/translate";
import { validateDate } from "src/lib/zod/validateDate";
import LabResultsCubit from "src/state/LabResultsCubit/LabResultsCubit";
import { TrackEvent, TrackType } from "src/state/Track/TrackCubit";
import { tracker } from "src/state/state";
import type { TranslationKey } from "src/types/translationKey";
import type { BloodGlucoseTag } from "src/ui/components/BloodGlucoseForm/BloodGlucoseTagSelector";
import BloodGlucoseTagSelector from "src/ui/components/BloodGlucoseForm/BloodGlucoseTagSelector";
import ErrorBox from "src/ui/components/StyledComponents/ErrorBox";
import Track from "src/ui/components/Track/Track";
import DateTimePicker from "../DateTimePicker/DateTimePicker";

const BloodGlucoseSchema = z.object({
  date: validateDate,
  bg: z.string().refine(
    (v) => {
      if (!v) return false;
      const bgRegex = questionnaireFieldValidation.bloodglucose;
      return bgRegex.test(v);
    },
    {
      message: translate("upload_labs.glucose.invalid_input_error")
    }
  )
});

type FormValues = z.infer<typeof BloodGlucoseSchema>;

const BloodGlucoseForm: FC<{
  onComplete: () => void;
}> = (props) => {
  const [errorMessage, setErrorMessage] = React.useState<TranslationKey>();
  const [displayTagSelector, setDisplayTagSelector] =
    React.useState<boolean>(false);
  const [selectedTag, setSelectedTag] = React.useState<BloodGlucoseTag>();

  const initialFormValues: FormValues = {
    bg: "",
    date: pickerCurrentTime()
  };

  const handleSubmit = async (formValues: FormValues): Promise<void> => {
    let error;

    const [dateTimeZone] = formValues.date.toString().split("[");
    try {
      const observation = LabResultsCubit.createObservationBloodGlucose(
        formValues.bg,
        dateTimeZone,
        selectedTag
      );

      error = await LabResultsCubit.storeSelfReportedLabValues(
        [observation],
        "messenger"
      );

      tracker.track(TrackEvent.BloodGlucoseForm, {
        type: TrackType.complete
      });
      void Blac.getBloc(LabResultsCubit).loadObservations();
    } catch (observationError: unknown) {
      reportErrorSentry(error);
      error = (observationError as Error).message;
    }

    if (error) {
      setErrorMessage("error_generic");
    } else {
      props.onComplete();
    }
  };

  const autoFormControls = useAutoFormControls({
    schema: BloodGlucoseSchema,
    initialValue: initialFormValues,
    validateOnChange: true,
    onSubmit: (data) => void handleSubmit(data as unknown as FormValues)
  });

  const [showPicker, setShowPicker] = React.useState(false);
  const handleFocusChange = () => {
    const isCoarse = matchMedia("(pointer:coarse)").matches;

    if (!isCoarse) {
      return;
    }

    setShowPicker(true);
  };

  return (
    <div>
      <h3 className="as-h4 center">
        <div style={{ opacity: 0.2 }}>
          <IconLabsBloodGlucoseDropWithSugarCube />
        </div>
        <Translate msg="upload_labs.glucose.title" />
      </h3>

      <nine-spacer s="md"></nine-spacer>

      <Track event={TrackEvent.BloodGlucoseForm} type={TrackType.open} />

      <DateTimePicker
        show={showPicker}
        onFinish={() => {
          setShowPicker(false);
        }}
        onChange={(dateTime) => {
          autoFormControls.setValue("date", dateTime);
        }}
        initialDate={autoFormControls.getValues().date}
        title={translate("upload_labs.glucose.date_input_label")}
        granularity="minute"
      />

      {errorMessage && (
        <>
          <nine-spacer s="sm"></nine-spacer>
          <ErrorBox data-severity="error">
            <Translate msg={errorMessage} fallback="error_generic" />
          </ErrorBox>
        </>
      )}

      <nine-spacer s="md"></nine-spacer>

      <AutoForm {...autoFormControls.props}>
        <DataFieldGroup>
          <AutoFormDataFieldDate
            onFocus={handleFocusChange}
            maxValue={pickerCurrentTime()}
            label={translate("upload_labs.glucose.date_input_label")}
            name="date"
            hourCycle={12}
            granularity="minute"
            hideTimeZone
          />

          <AutoFormDataField
            inputMode="numeric"
            onChange={() => {
              setDisplayTagSelector(true);
            }}
            name="bg"
            mask="00[0].00[0]"
            label={translate("upload_labs.glucose.value_input_label")}
            affix="mg/dL"
          />
        </DataFieldGroup>

        <nine-spacer s="lg" />

        {displayTagSelector && (
          <>
            <BloodGlucoseTagSelector
              onTagSelected={(tag) => {
                setErrorMessage(undefined);
                setSelectedTag(tag);
              }}
            />

            <nine-spacer s="lg" />
          </>
        )}

        <Button
          type="submit"
          theme="charcoal"
          style={{ color: "white", margin: "auto" }}
        >
          <Translate msg="confirm" />
        </Button>
      </AutoForm>

      <Loader fixed absolute background overContent />
    </div>
  );
};

export default BloodGlucoseForm;
