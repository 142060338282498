import { SubscriptionDetailsResponse } from "@9amhealth/openapi";
import { Cubit } from "blac-next";
import { authenticationState, subscriptionState } from "src/state/state";
import { SAML_FUNNEL_KEY_SESSION_STORAGE_KEY } from "src/lib/useSamlAuth";
import { Props } from "components/WithAuth/WithAuth";
import { PayerId } from "src/constants/payers";

export enum View {
  Loading = "loading",
  Reauth = "reauth",
  Login = "login",
  App = "app"
}

export interface WithAuthState {
  view: View;
}

export enum AuthenticationMethod {
  ANONYMOUS = "ANONYMOUS",
  PASSWORD = "PASSWORD",
  ROLE_SWITCH = "ROLE_SWITCH",
  EXISTING_TOKEN = "EXISTING_TOKEN",
  GOOGLE = "GOOGLE",
  SAML2 = "SAML2",
  TRANSCARENT_SSO = "TRANSCARENT_SSO"
}

export interface Dependencies {
  props: Props;
  navigate: (path: string, options?: { replace: boolean }) => void;
  allSubscriptions?: SubscriptionDetailsResponse[];
  payerId?: string;
  sessionAuthenticationMethod?: string;
  authStage: "noAuth" | "anonymous" | "partialAuth" | "fullAuth";
}

export class WithAuthCubit extends Cubit<WithAuthState, Props> {
  componentProps: Props;
  dependencies?: Dependencies;

  constructor(props: Props) {
    super({
      view: View.Loading
    });

    this.componentProps = props;
  }

  public readonly checkPassedAuthCheck = (): boolean => {
    const { hasActiveSubscription } = subscriptionState;

    if (this.dependencies?.authStage === "fullAuth") {
      return true;
    }

    if (
      this.dependencies?.authStage === "anonymous" &&
      this.componentProps.createAnonymousUser
    ) {
      return true;
    }

    // for funnel when user is allowed to have an email but not a password, or when the user has a subscription
    if (
      this.dependencies?.authStage === "partialAuth" &&
      (this.componentProps.allowPartialAuth || hasActiveSubscription)
    ) {
      return true;
    }

    return false;
  };

  /**
   * Returns 'pass' if the user has a transcarent payer and is authenticated with a valid sessionAuthenticationMethod
   * Returns 'fail' if the user has a transcarent payer and is not authenticated with a valid sessionAuthenticationMethod
   * Returns 'missing-data' if the payerId or sessionAuthenticationMethod is undefined
   */
  public readonly checkPassedTranscarentAuthCheck = (): "pass" | "fail" => {
    const { payerId, sessionAuthenticationMethod } = this.dependencies ?? {};

    const isTranscarentPayerId = payerId === PayerId.TRANSCARENT;
    if (!isTranscarentPayerId) return "pass";

    const isSamlAuthenticated =
      sessionAuthenticationMethod &&
      [
        AuthenticationMethod.SAML2,
        AuthenticationMethod.TRANSCARENT_SSO
      ].includes(sessionAuthenticationMethod);
    return isSamlAuthenticated ? "pass" : "fail";
  };

  get checkPassedSubscriptionCheck(): boolean {
    if (this.componentProps.requiresSubscription !== true) {
      return true;
    }

    const { checkHasPlan, getFullSubscription } = subscriptionState;
    const subscription = getFullSubscription();
    const userHasTreatmentPlan = checkHasPlan(subscription?.id);

    return Boolean(userHasTreatmentPlan);
  }

  public setView = (): void => {
    const passAuthCheck = this.checkPassedAuthCheck();
    const allRequiredDataAvailable = [
      this.dependencies?.sessionAuthenticationMethod
    ].every(Boolean);
    let view = View.App;

    // show login if user is not authenticated
    if (!passAuthCheck) {
      view = View.Login;
    }

    // Show loading while creating anonymous user
    if (this.componentProps.createAnonymousUser && !passAuthCheck) {
      void authenticationState.createAnonymousAccount();
      view = View.Loading;
    }

    const transcarentAccessStatus = this.checkPassedTranscarentAuthCheck();
    // show reauth if user is transcarent but not authenticate with a valid sessionAuthenticationMethod
    if (transcarentAccessStatus === "fail" && allRequiredDataAvailable) {
      view = View.Reauth;
    }

    this.patch({ view });
  };

  public checkAndNavigateToPresetFunnel = (): void => {
    const { navigate } = this.dependencies ?? {};

    const presetFunnel = sessionStorage.getItem(
      SAML_FUNNEL_KEY_SESSION_STORAGE_KEY
    );
    if (
      presetFunnel &&
      typeof this.dependencies?.allSubscriptions !== "undefined" &&
      !this.checkPassedSubscriptionCheck &&
      this.componentProps.requiresSubscription
    ) {
      navigate?.(`/signup/${presetFunnel}`, { replace: true });
      sessionStorage.removeItem(SAML_FUNNEL_KEY_SESSION_STORAGE_KEY);
    }
  };

  initializeDependencies = (deps: Dependencies): void => {
    this.dependencies = deps;
    this.componentProps = deps.props;

    this.setView();
    this.checkAndNavigateToPresetFunnel();
  };
}
