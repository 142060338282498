import { Cubit } from "blac";
import type { NavigateFunction } from "react-router-dom";

export enum AppPopup {
  medicalInsurance = "medical-insurance",
  rpmDailyReadings = "dailyReadings",
  uploadLabReport = "uploadLabReport",
  enterBloodGlucose = "enterBloodGlucose",
  enterWaistCircumference = "enterWaistCircumference",
  enterBloodPressure = "enterBloodPressure",
  enterWeight = "enterWeight",
  profileSelectPCP = "profileSelectPCP",
  profileSelectPharmacy = "profileSelectPharmacy",
  profileEnterPharmacyInsurance = "profileEnterPharmacyInsurance",
  questionnaire = "questionnaire",
  iframe = "iframe",
  article = "article",
  teamDetails = "teamDetails",
  rxDietNutritionProgram = "rxDietNutritionProgram",
  healthSyncSetup = "healthSyncSetup",
  lifestyleProgram = "lifestyleProgram",
  appointment = "appointment",
  appointmentBooking = "appointmentBooking",
  rescheduleAppointment = "rescheduleAppointment",
  initialLab = "initialLab",
  labcorp = "labcorp",
  sanityContent = "sanityContent",
  onboardingTasks = "onboardingTasks",
  fastPass = "fastPass",
  behavioralHealth = "behavioralHealth"
}

export type ReservedQueryParameters = {
  /**
   * set the z-index of the popup
   */
  z?: string;

  /**
   * if set to 'false', the popup will only open if it was triggered with the `.openPopup` method.
   * If the user reloads the page, the popup will not open.
   * @default 'true'
   */
  stay?: "false" | "true";
};

export type AppQueryPopupOpenOptions = {
  replace?: boolean;
  zIndex?: number;
  additionalParameters?: Record<string, string> & ReservedQueryParameters;
  onEvent?: Record<string | "popupClosed", () => void>;
};

type AppQueryPopupsCallbackType = "onClose";
type AppQueryPopupsCallbackMethod = (
  query: AppPopup | string | null
) => unknown;

export default class AppQueryPopupsBloc extends Cubit<null> {
  navigate: NavigateFunction | null = null;
  openedByMethod: AppPopup | null = null;

  callbacks: Record<
    AppQueryPopupsCallbackType,
    AppQueryPopupsCallbackMethod[]
  > = {
    onClose: []
  };

  constructor() {
    super(null);
  }

  setNavigate = (navigate: NavigateFunction) => {
    this.navigate = navigate;
  };

  eventListeners: { event: string; callback: (() => void) | undefined }[] = [];

  openPopup = (popup: AppPopup, options: AppQueryPopupOpenOptions = {}) => {
    const { replace = false, additionalParameters = {} } = options;

    // update event listeners
    this.eventListeners = Object.keys(options.onEvent ?? {}).map((event) => ({
      event,
      callback: options.onEvent?.[event]
    }));
    this.addCloseListener(popup);

    if (this.navigate) {
      const params = new URLSearchParams();

      Object.keys(additionalParameters).forEach((key) => {
        params.set(key, additionalParameters[key]);
      });

      if (options.zIndex) {
        params.set("z", options.zIndex.toString());
      }
      params.set("popup", popup);
      this.setCurrentQuery(popup);

      this.navigate(`?${params.toString()}`, {
        replace
      });

      this.openedByMethod = popup;
    }
  };

  handleQuestionnaireSaved = () => {
    if (this.currentDialogStayDisabled) {
      this.closePopup();
    }
  };

  addCloseListener = (popup: AppPopup) => {
    document.removeEventListener(
      "nineQuestionnaireSaved",
      this.handleQuestionnaireSaved
    );

    if (popup === AppPopup.questionnaire) {
      document.addEventListener(
        "nineQuestionnaireSaved",
        this.handleQuestionnaireSaved,
        { once: true }
      );
    }

    // add event listeners for current popup instance
    this.eventListeners.forEach(({ event, callback }) => {
      if (event && callback) {
        document.addEventListener(event, callback, { once: true });
      }
    });
  };

  closePopup = () => {
    if (!this.currentQuery) return;
    this.currentQuery = "";
    document.dispatchEvent(new CustomEvent("popupClosed", { bubbles: true }));
    this.removeEventListeners();

    if (this.navigate) {
      this.navigate(`?`);
    } else {
      history.pushState({}, "", "?");
    }

    document.body.classList.remove("backdrop-no-scroll");
  };

  removeEventListeners = () => {
    this.eventListeners.forEach(({ event, callback }) => {
      if (event && callback) {
        document.removeEventListener(event, callback);
      }
    });
  };

  currentDialogStayDisabled = false;
  currentQuery: AppPopup | string | "" = "";
  setCurrentQuery = (
    query: AppPopup | string | "",
    stay: ReservedQueryParameters["stay"] | string = "true"
  ) => {
    if (query === this.currentQuery) return;
    const closed = this.currentQuery && query === "";
    this.currentDialogStayDisabled = stay === "false";

    if (closed) {
      // call all onClose callbacks
      this.callbacks.onClose.forEach((callback) => callback(query));
      this.removeEventListeners();
    }

    if (query) {
      this.currentQuery = query as AppPopup;
    } else {
      this.currentQuery = "";
    }

    if (!this.openedByMethod && stay === "false") {
      this.closePopup();
      return;
    }
  };

  dismissModal: HTMLIonModalElement | null = null;
  setModalDismiss = (dismiss: typeof this.dismissModal) => {
    if (!dismiss) return;
    this.dismissModal = dismiss;
  };
}

export const AppQueryPopupsController = new AppQueryPopupsBloc();

/**
 * Utility Hooks
 */
type useAppQueryPopupOpenMethod = (options?: AppQueryPopupOpenOptions) => void;
type useAppQueryPopupReturnType = [useAppQueryPopupOpenMethod, () => void];

export const useAppQueryPopup = (
  popup: AppPopup,
  options: {
    onClose?: () => unknown;
  } = {}
): useAppQueryPopupReturnType => {
  return [
    (o?: AppQueryPopupOpenOptions) => {
      AppQueryPopupsController.openPopup(popup, {
        ...o,
        onEvent: {
          popupClosed: () => {
            options.onClose?.();
          },
          ...o?.onEvent
        }
      });
    },
    () => AppQueryPopupsController.closePopup()
  ];
};
