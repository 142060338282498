import styled from "@emotion/styled";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { globalEvents } from "src/constants/globalEvents";
import reportErrorSentry from "src/lib/reportErrorSentry";
import { AppQueryPopupsController } from "../AppQueryPopupsBloc";
import AppointmentsBloc from "src/state/AppointmentsBloc/AppointmentsBloc";
import InDialog from "src/ui/components/InDialog/InDialog";
import Loader from "src/ui/components/Loader/Loader";
import useGoToOrBack from "src/ui/hooks/useGoToOrBack";
import {
  ErrorPageLayout,
  CenterEl
} from "src/ui/components/SentryBoundary/ErrorPage";
import translate from "src/lib/translate";
import Translate from "../../Translate/Translate";
import { OpenBrowser } from "src/hybrid/components/Browser";
import clsx from "clsx";
import { ionModalStyles, ionModalAperoStyles } from "./IframePopup";
import { isAperoLink } from "lib/Urls";

const DialogIframe = styled.iframe`
  outline: none;
  border: none;
  width: 100%;
  height: 100%;
  padding-bottom: 0 !important;
`;

const RescheduleAppointmentDialog: FC<{
  returnUrl: string;
}> = ({ returnUrl }) => {
  const [searchParams] = useSearchParams();
  const appointmentId = searchParams.get("id") ?? "";
  const reschedulingUrlParam = searchParams.get("url") ?? "";

  const navigate = useGoToOrBack();
  const [reschedulingUrl, setReschedulingUrl] =
    useState<string>(reschedulingUrlParam);
  const [error, setError] = useState<boolean>();

  const onClose = (dispatchEvent = true) => {
    if (dispatchEvent) {
      document.dispatchEvent(
        new CustomEvent(globalEvents.APPOINTMENT_RESCHEDULED, {
          bubbles: true,
          composed: true
        })
      );
    }

    AppQueryPopupsController.closePopup();

    navigate(returnUrl, {
      multiBack: true,
      replace: true
    });
  };

  const isGetlabsAppointment = useMemo(
    () => reschedulingUrl.includes("getlabs"),
    [reschedulingUrl]
  );
  const isAperoUrl = isAperoLink(reschedulingUrl);

  useEffect(() => {
    if (isGetlabsAppointment) {
      void OpenBrowser(reschedulingUrl, {
        presentationStyle: "popover",
        useBaseUrl: false
      }).finally(onClose);
    }
  }, [isGetlabsAppointment]);

  useEffect(() => {
    if (reschedulingUrl) {
      return;
    }

    void AppointmentsBloc.getAppointmentReschedulingUrl(appointmentId)
      .then((url) => {
        setReschedulingUrl(url);
      })
      .catch((e: unknown) => {
        reportErrorSentry(e);
        setError(true);
      });
  }, []);

  if (isGetlabsAppointment) {
    return null;
  }

  return (
    <InDialog
      width="min(calc(100% - 4rem), 120rem)"
      height="min(calc(100% - 4rem), 70rem)"
      simple
      backdropDismiss={false}
      title={translate("appointment.popup.title", {
        context: "reschedule"
      })}
      onClose={onClose}
      returnUrl={returnUrl}
      className={clsx(ionModalStyles, {
        [ionModalAperoStyles]: isAperoUrl
      })}
    >
      {error && (
        <ErrorPageLayout>
          <CenterEl>
            <nine-heading>
              <nine-spacer s="xs"></nine-spacer>
              <h4 className="m0 color-c-80">
                <Translate msg="errorPage.reschedule" />{" "}
              </h4>
              <nine-spacer s="md"></nine-spacer>
              <p className="m0 color-c-80">
                <Translate msg="help.contactUsGeneric" />
                <nine-spacer s="xs"></nine-spacer>
                <Translate msg="help.thankYouMessage" />
              </p>
            </nine-heading>
            <nine-spacer s="lg"></nine-spacer>
            <nine-button
              onClick={(): void => {
                navigate("/app/chat");
              }}
            >
              <Translate msg="chatWithCareTeam" />
            </nine-button>
          </CenterEl>
        </ErrorPageLayout>
      )}
      {reschedulingUrl && !error && (
        <DialogIframe src={reschedulingUrl} id="schedule-appointment" />
      )}
      {!reschedulingUrl && !error && <Loader active fixed />}
    </InDialog>
  );
};

export default RescheduleAppointmentDialog;
