/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An optional object, providing the actual result.
 */
export type LabPreconditionsFulfillmentCheckResultResponse = {
    /**
     * Status of precondition check.
     */
    preconditionsCheckStatus: LabPreconditionsFulfillmentCheckResultResponse.preconditionsCheckStatus;
    /**
     * List of the reasons why labs cannot be ordered for this user.
     */
    reasons: Array<'NO_STANDING_LAB_ORDER_AVAILABLE' | 'ONGOING_LAB_ORDER' | 'UPLOAD_LAB_REPORTS_TASK_NOT_FINISHED' | 'INITIAL_LAB_QUESTIONNAIRE_TASK_NOT_FINISHED' | 'USER_IS_NOT_SUBSCRIBED_TO_LAB_TESTS' | 'MISSING_PRIMARY_MEDICAL_INSURANCE' | 'MISSING_PAYER' | 'MISSING_GUARANTOR' | 'UNKNOWN_PLAN_OWNER' | 'CONNECTIVE_HEALTH_DATA_SYNC_NOT_FINISHED' | 'PARTNER_ELIGIBILITY_FAILED' | 'DETECTED_ISSUE'>;
};

export namespace LabPreconditionsFulfillmentCheckResultResponse {

    /**
     * Status of precondition check.
     */
    export enum preconditionsCheckStatus {
        OK = 'OK',
        FAILED = 'FAILED',
    }


}

