import type { FC } from "react";
import React from "react";
import translate from "src/lib/translate";
import UserCubit, {
  ProfileProgramMembership
} from "src/state/UserCubit/UserCubit";
import { useBloc } from "src/state/state";
import type { SignupSuccessPageProps } from "src/ui/components/SignupCustomContent/SignupSuccessPage";
import Translate from "src/ui/components/Translate/Translate";

const SubtitleWithProgramContext: FC = () => {
  const [, { programMemberships }] = useBloc(UserCubit);
  const selectefProgram = programMemberships?.find((p) => p.program)?.program;
  const program =
    selectefProgram ?? ProfileProgramMembership.ALLINONE_HEALTHCARE_CONCIERGE;

  return (
    <Translate
      msg="checkout.success.subtitle"
      variables={{
        program: translate(`program.title_${program}`)
      }}
    />
  );
};

const genericEnrollCheckoutSuccessScreenProps = (): SignupSuccessPageProps => ({
  title: <Translate msg="checkout.success.title" />,
  subtitle: <SubtitleWithProgramContext />,
  titleSpacer: false
});

export default genericEnrollCheckoutSuccessScreenProps;
