import { useBloc } from "@blac/react";
import styled from "@emotion/styled";
import React, { FC } from "react";
import { IconTaskCalendar } from "src/constants/icons";
import InDialog from "../InDialog/InDialog";
import Translate from "../Translate/Translate";
import ConfirmingAppointmentInfoBloc from "./ConfigmingAppointmentInfoBloc";

const Centered = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 0 2rem 1rem;
  svg {
    width: 3rem;
    height: 3rem;
  }
`;

const ConfirmingAppointmentInfo: FC = () => {
  const [{ showConfirmInfo }] = useBloc(ConfirmingAppointmentInfoBloc);

  if (showConfirmInfo) {
    return (
      <InDialog
        simple
        height="260px"
        width="366px"
        backdropDismiss={false}
        alwaysModal
        key="request"
        background="var(--color-white)"
      >
        <Centered>
          <div>
            <IconTaskCalendar />
            <h5 style={{ fontWeight: 500 }}>
              <Translate msg="confirmingAppointment.title" />
            </h5>
            <p>
              <Translate msg="confirmingAppointment.description" />
            </p>
          </div>
        </Centered>
      </InDialog>
    );
  }

  return null;
};

export default ConfirmingAppointmentInfo;
