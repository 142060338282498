import type { AnsweredQuestionnaire } from "@9amhealth/openapi";
import styled from "@emotion/styled";
import type { FC } from "react";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  IconAngryFace,
  IconHappyFace,
  IconNeutralFace
} from "src/constants/icons";
import {
  FEEDBACK_BAD_QUESTIONNAIRE_ID,
  FEEDBACK_BAD_QUESTIONNAIRE_RATING_TEXT_QUESTION_ID,
  FEEDBACK_GOOD_QUESTIONNAIRE_ID,
  FEEDBACK_GOOD_QUESTIONNAIRE_RATING_QUESTION_ID,
  FEEDBACK_GOOD_QUESTIONNAIRE_RATING_TEXT_QUESTION_ID,
  METADATA_CONTENT_VALUE,
  METADATA_MESSAGE_TYPE,
  METADATA_NPS_FEEDBACK_RATING,
  METADATA_NPS_FEEDBACK_RATING_NUMBER,
  METADATA_NPS_FEEDBACK_RATING_TEXT,
  METADATA_NPS_FEEDBACK_SOURCE_ID,
  METADATA_ORIGINAL_SOURCE
} from "src/constants/misc";
import translate from "src/lib/translate";
import { TrackEvent } from "src/state/Track/TrackCubit";
import { WebsocketMessageType } from "src/state/WebSocketBloc/WebSocketBloc";
import { tracker, useBloc, websocketState } from "src/state/state";
import {
  AppPopup,
  AppQueryPopupsController
} from "src/ui/components/AppQueryPopups/AppQueryPopupsBloc";
import { ChatBloc, ChatMessageMetaType } from "src/ui/components/Chat/ChatBloc";
import ChatMessageContext from "src/ui/components/Chat/ChatMessageContext";

export type Rating = "bad" | "good" | "okay";

export interface Answer {
  questionId: string;
  fieldValue: number | string;
  fieldType: "number" | "text";
}

const Button = styled.button`
  display: flex;
  flex-direction: column;
  padding: 7px 12px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 64px;
  height: 64px;
  border-radius: 8px;
  margin-right: 10px;
  --border-color: #6fc696;
  --bg-color: var(--color-afternoon-green);

  border: 2px solid var(--border-color);
  background-color: var(--bg-color);

  @media screen and (min-width: 550px) {
    margin-right: 16px;
  }

  &[data-rating="good"] {
    --border-color: #6fc696;
    --bg-color: var(--color-afternoon-green);
  }

  &[data-rating="okay"] {
    --border-color: var(--color-sunrise-orange);
    --bg-color: var(--color-afternoon-yellow);
  }

  &[data-rating="bad"] {
    --border-color: #ff7663;
    --bg-color: #ffd4c8;
  }

  :disabled {
    --border-color: var(--color-gray-lighter);
    --bg-color: var(--color-gray-fog);
  }

  &:last-child {
    margin-right: 0;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (min-width: 550px) {
    justify-content: flex-start;
  }
`;

const Text = styled.div`
  color: var(--color-charcoal-80);
  font-weight: 500;

  &[aria-disabled="true"] {
    color: var(--color-gray);
  }
`;

export const icons: Record<
  Rating,
  ({ disabled }: { disabled?: boolean | undefined }) => React.JSX.Element
> = {
  good: IconHappyFace,
  okay: IconNeutralFace,
  bad: IconAngryFace
};

const FeedbackButton = ({
  rating,
  handleClick,
  disabled
}: {
  rating: Rating;
  handleClick: (rating: Rating) => void;
  disabled: boolean;
}) => {
  const text = translate(`feedback_${rating}`);
  const Icon = icons[rating];

  return (
    <Button
      data-rating={rating}
      disabled={disabled}
      onClick={() => handleClick(rating)}
    >
      <Icon disabled={disabled} />
      <Text aria-disabled={disabled}>{text.toUpperCase()}</Text>
    </Button>
  );
};

const Feedback: FC<{ sourceId?: string }> = () => {
  const [answered, setAnswered] = useState(false);
  const userRating = useRef<Rating | undefined>(undefined);
  const messageContext = useContext(ChatMessageContext);

  const [, { getMessagesThatContainMetadataAttribute }] = useBloc(ChatBloc);

  const eventListener = (event: Event) => {
    if (!userRating.current) {
      return;
    }

    const customEvent = event as CustomEvent<AnsweredQuestionnaire>;
    if (
      customEvent.detail.questionnaireRef.id ===
        FEEDBACK_GOOD_QUESTIONNAIRE_ID ||
      customEvent.detail.questionnaireRef.id === FEEDBACK_BAD_QUESTIONNAIRE_ID
    ) {
      setAnswered(true);

      const answers = (customEvent.detail.answers.json ?? []) as Answer[];

      const ratingNumberObj = answers.find(
        (answer) =>
          answer.questionId === FEEDBACK_GOOD_QUESTIONNAIRE_RATING_QUESTION_ID
      );
      const ratingNumber = ratingNumberObj?.fieldValue
        ? ratingNumberObj.fieldValue
        : undefined;

      const ratingTextObj = answers.find(
        (answer) =>
          answer.questionId ===
            FEEDBACK_GOOD_QUESTIONNAIRE_RATING_TEXT_QUESTION_ID ||
          answer.questionId ===
            FEEDBACK_BAD_QUESTIONNAIRE_RATING_TEXT_QUESTION_ID
      );
      const ratingText = ratingTextObj?.fieldValue
        ? ratingTextObj.fieldValue
        : undefined;

      const metadata = {
        [METADATA_CONTENT_VALUE]: true,
        [METADATA_MESSAGE_TYPE]: ChatMessageMetaType.npsFeedback,
        [METADATA_ORIGINAL_SOURCE]: "9am-frontend",
        [METADATA_NPS_FEEDBACK_RATING]: userRating.current,
        [METADATA_NPS_FEEDBACK_RATING_NUMBER]: ratingNumber,
        [METADATA_NPS_FEEDBACK_RATING_TEXT]: ratingText,
        [METADATA_NPS_FEEDBACK_SOURCE_ID]: messageContext.id
      };

      void websocketState.send({
        type: WebsocketMessageType.sendMessage,
        payload: {
          contentType: "text/html",
          contentValue: "",
          metadata
        }
      });
    }
    return;
  };

  useEffect(() => {
    document.addEventListener("nineQuestionnaireSaved", eventListener, {
      once: true
    });

    const messages = getMessagesThatContainMetadataAttribute(
      METADATA_NPS_FEEDBACK_SOURCE_ID,
      messageContext.id
    );

    if (messages.length > 0) {
      setAnswered(true);
    }

    return () => {
      document.removeEventListener("nineQuestionnaireSaved", eventListener);
    };
  }, []);

  const handleClick = (questionnaireId: string, rating?: Rating) => {
    userRating.current = rating;

    AppQueryPopupsController.openPopup(AppPopup.questionnaire, {
      additionalParameters: {
        questionnaireId,
        title: translate("yourFeedback"),
        fullscreen: "true",
        "dialog-type": "feedback"
      }
    });

    tracker.track(TrackEvent.ExperienceRated, {
      data: {
        Rating: rating
      }
    });
  };

  return (
    <Wrapper data-id={messageContext.id}>
      <FeedbackButton
        rating="good"
        disabled={answered}
        handleClick={(rating: Rating) =>
          handleClick(FEEDBACK_GOOD_QUESTIONNAIRE_ID, rating)
        }
      />
      <FeedbackButton
        rating="okay"
        disabled={answered}
        handleClick={(rating) =>
          handleClick(FEEDBACK_GOOD_QUESTIONNAIRE_ID, rating)
        }
      />
      <FeedbackButton
        rating="bad"
        disabled={answered}
        handleClick={(rating) =>
          handleClick(FEEDBACK_BAD_QUESTIONNAIRE_ID, rating)
        }
      />
    </Wrapper>
  );
};

export default Feedback;
