import type { GetMessagesResponseItem } from "@9amhealth/openapi";
import { Cubit } from "blac";
import { logSentryBreadcrumb } from "src/lib/addSentryBreadcrumb";
import envVariables from "src/lib/envVariables";
import { FeatureFlagName, featureFlags } from "src/lib/featureFlags";
import reportErrorSentry from "src/lib/reportErrorSentry";
import { StorageController } from "../StorageBloc/StorageBloc";
import { authenticationState } from "../state";
import { WebSocketConnection } from "./WebSocketConnection";

export enum WebsocketMessageType {
  ping = "ping",
  authenticate = "auth.v1.authenticate",
  authenticateSuccess = "auth.v1.authenticate-success",
  authenticateFailed = "auth.v1.authenticate-failed",
  unauthorized = "websocket.v1.error.unauthorized",
  sendMessage = "messaging.v1.send-message",
  receivedMessage = "messaging.v1.received-message",
  taskCreated = "tasks.v1.task_created",
  taskStatusUpdated = "tasks.v1.task_status_updated"
}

export enum WebsocketLifecycleEvent {
  connected = "connected",
  disconnected = "disconnected"
}

export interface WebsocketTaskStatusUpdatedPayload {
  group: string;
  newStatus: string;
  oldStatus: string;
  program: string;
  slug: string;
  taskId: string;
  updatingUserId: string;
  userId: string;
}

export type WebsocketPayload =
  | GetMessagesResponseItem
  | WebsocketTaskStatusUpdatedPayload
  | object;

export interface WebsocketMessage {
  type: WebsocketMessageType;
  payload: WebsocketPayload;
}

export type ObserverCallback = (
  data?: WebsocketMessage | { type: WebsocketLifecycleEvent; payload?: unknown }
) => void;

interface WebSocketObservers {
  id: string;
  type: WebsocketLifecycleEvent | WebsocketMessageType;
  callback: ObserverCallback;
}

interface AddWebSocketObserverResponse {
  id: string;
  remove: () => void;
}

const MESSAGE_QUEUE_KEY = "message-queue";

export default class WebSocketBloc extends Cubit<null> {
  observers: WebSocketObservers[] = [];
  messageQueue: WebsocketMessage[] = [];
  websocket: WebSocketConnection | null = null;
  authenticatedWithToken: string | null = null;
  appIsActive = true;
  static WS_URL = `${envVariables.WS_BASE_URL}/v1/socket`;

  websocketConnectingPromise?: Promise<void>;

  log = (message: string, deets?: unknown) => {
    if (featureFlags.getFlag(FeatureFlagName.loggingWebsocket)) {
      // eslint-disable-next-line no-console
      console.warn(`[WS] ${message}`, deets ?? undefined);
    }

    logSentryBreadcrumb("websocket", [message, deets]);
  };

  constructor() {
    super(null);
  }

  /**
   * ===== WEBSOCKET =====
   **/

  /**
   * Connect to the websocket and setup authentication, add listeners.
   * Clean up any existing websocket connection first.
   */
  connect = async (): Promise<void> => {
    if (this.websocket) {
      this.log("Already connected");
      void this.authenticate();
      return;
    }
    const WS = new WebSocketConnection();
    this.websocket = WS;
    this.addEventListeners();
    await WS.connect();
  };

  addEventListeners = () => {
    if (!this.websocket) {
      throw new Error("No websocket");
    }
    this.websocket.addEventListener("message", this.handleIncomingMessage);
    this.websocket.addEventListener("open", () => void this.authenticate());
  };

  pauseSending = false;
  pauseSendingUntilAuthSuccess = () => {
    this.log("Pausing sending until auth success");
    this.pauseSending = true;
    const observer = this.addObserver(
      WebsocketMessageType.authenticateSuccess,
      () => {
        this.log("Resuming sending");
        observer.remove();
        this.pauseSending = false;
        void this.processMessageQueue();
      }
    );
  };

  disconnect = async () => {
    this.log("Disconnecting from websocket");
    this.websocket?.close();
  };

  handleWebscoketClose = () => {
    this.log("Websocket closed");
    this.dispatchLifecycleEvent(WebsocketLifecycleEvent.disconnected);
    this.websocket = null;
  };

  /**
   * Stringify and send a message to the websocket connection
   * @param message - The message to send
   * @throws Error - If the websocket is not connected
   */
  readonly sendMessageInWebsocket = async (message: WebsocketMessage) => {
    if (!this.websocket) {
      throw new Error("Websocket not connected");
    }

    this.log(`Sending message:`, message);

    const { type, payload } = message;
    this.websocket.send(JSON.stringify({ type, payload }));
  };

  authenticate = async (): Promise<void> => {
    const { accessToken } = authenticationState;
    const open = this.websocket?.readyState === WebSocket.OPEN;
    if (!open) {
      return;
    }

    if (!accessToken) {
      throw new Error("No access token");
    }

    const message = {
      type: WebsocketMessageType.authenticate,
      payload: {
        accessToken
      }
    };

    const handleError = (
      data:
        | WebsocketMessage
        | {
            type: WebsocketLifecycleEvent;
            payload?: unknown;
          }
        | undefined,
      authObservers: AddWebSocketObserverResponse[],
      reject: (reason?: unknown) => void,
      errorMessage: string
    ) => {
      this.log(errorMessage, data);
      authObservers.forEach((o) => o.remove());
      void this.disconnect();
      void authenticationState.reportFaultyAccessToken();
      reject(new Error(errorMessage));
    };

    return new Promise((resolve, reject) => {
      const authObservers = [
        this.addObserver(WebsocketMessageType.authenticateSuccess, (data) => {
          this.log("Authentication success", data);
          authObservers.forEach((o) => o.remove());
          this.authenticatedWithToken = accessToken;
          void this.processMessageQueue();
          resolve();
        }),
        this.addObserver(WebsocketMessageType.authenticateFailed, (data) => {
          handleError(data, authObservers, reject, "Authentication failed");
        }),
        this.addObserver(WebsocketMessageType.unauthorized, (data) => {
          handleError(data, authObservers, reject, "Unauthorized");
        })
      ];

      if (this.websocket) {
        this.log("Sending authentication message");
        this.websocket.send(JSON.stringify(message));
      } else {
        this.log("No websocket, not sending authentication message");
        reject(new Error("No WS open, not sending auth"));
      }
    });
  };

  /**
   * ===== INCOMING MESSAGES =====
   */

  /**
   * Handle incoming messages from the websocket connection
   * @param event - The message event
   */
  handleIncomingMessage = (ev: Event) => {
    const event = ev as MessageEvent;
    const isWebsocketMessage =
      typeof event.data === "string" && event.data.startsWith("{");

    if (!isWebsocketMessage) {
      this.log(`Incoming message [UNKNOWN DATA TYPE]:`, event.data);
      return;
    }

    const message = JSON.parse(event.data) as WebsocketMessage;
    this.log(`Incoming message:`, message);

    this.notifyObservers(message);
  };

  /**
   * ===== OUTGOING MESSAGES =====
   */

  /**
   * Send a message to the websocket connection
   */
  send = async (message: WebsocketMessage) => {
    await this.addMessageToQueue(message);
    if (this.websocket && this.websocket.readyState === WebSocket.OPEN) {
      await this.processMessageQueue();
    } else {
      throw new Error("Websocket not connected");
    }
  };

  /**
   * Add a message to the message queue, and store it in local storage
   * @param message - The message to add to the queue
   */
  addMessageToQueue = async (message: WebsocketMessage) => {
    this.log(`Adding message to queue:`, message);
    let newQueue = [...this.messageQueue, message];
    // filter out duplicate messages

    if ("contentValue" in message.payload) {
      newQueue = newQueue.filter(
        (m, i, self) =>
          i ===
          self.findIndex(
            (m2) =>
              (m2.payload as GetMessagesResponseItem).contentValue ===
              (m.payload as GetMessagesResponseItem).contentValue
          )
      );
    }
    this.messageQueue = newQueue;
    this.saveMessageQueue();
  };

  removeLocalMessage = (message: WebsocketMessage) => {
    this.log("Manually removing messaage from queue", message);
    this.messageQueue = this.messageQueue.filter((m) => m !== message);
    this.saveMessageQueue();
  };

  saveMessageQueue = () => {
    StorageController.setItem(MESSAGE_QUEUE_KEY, this.messageQueueToKeep);
  };

  get messageQueueToKeep(): string {
    // remove pings
    const messagesToSave = this.messageQueue.filter((m) => m.type !== "ping");
    return JSON.stringify(messagesToSave);
  }

  /**
   * Process the message queue, sending each message to the websocket connection
   * and removing it from the queue if successful
   */
  processMessageQueue = async () => {
    if (this.pauseSending) {
      this.log("Sending paused, not sending");
      return;
    }

    if (this.messageQueue.length > 0) {
      this.log(`Processing message queue, length: ${this.messageQueue.length}`);
    }

    for (const message of this.messageQueue) {
      try {
        await this.sendMessageInWebsocket(message);
        this.messageQueue = this.messageQueue.filter((m) => m !== message);
        this.saveMessageQueue();
      } catch (e) {
        reportErrorSentry(e);
      }
    }
  };

  /**
   * ===== OBSERVERS =====
   **/

  /**
   * Add an observer to the websocket connection for messages of lifecycle events
   * @param type - The type of message or event to listen for
   * @param callback - The callback to run when the event is triggered
   **/
  addObserver = (
    type: WebsocketLifecycleEvent | WebsocketMessageType,
    callback: ObserverCallback
  ): AddWebSocketObserverResponse => {
    const id = `${type}-${Date.now()}`;
    this.log(`Adding observer: `, {
      type,
      id
    });

    this.observers.push({
      id,
      type,
      callback
    });

    return {
      id,
      remove: () => {
        this.observers = this.observers.filter((o) => o.id !== id);
      }
    };
  };

  dispatchLifecycleEvent = (event: WebsocketLifecycleEvent) => {
    this.log(`Dispatching lifecycle event:`, { event });
    this.notifyObservers({
      type: event
    });
  };

  /**
   * Notify all observers of a message
   * @param data - The message to send to observers
   **/
  notifyObservers = (
    data: WebsocketMessage | { type: WebsocketLifecycleEvent }
  ) => {
    const observers = this.observers.filter((o) => o.type === data.type);
    if (observers.length > 0) {
      this.log(`Notifying observers: type: ${data.type}`, {
        observers,
        message: data
      });
      observers.forEach((o) => o.callback(data));
    }
  };
}
