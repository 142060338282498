import {
  useEffect,
  useLayoutEffect,
  useState,
  useCallback,
  RefObject
} from "react";

interface Size {
  width: number;
  height: number;
}

export function useElementDimensions<T extends HTMLElement = HTMLDivElement>(
  ref: RefObject<T>
): [Size] {
  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0
  });

  const handleSize = useCallback(() => {
    setSize({
      width: ref.current?.offsetWidth ?? 0,
      height: ref.current?.offsetHeight ?? 0
    });
  }, [ref.current?.offsetHeight, ref.current?.offsetWidth]);

  useEffect(() => {
    if (!ref.current) return undefined;

    const resizeObserver = new ResizeObserver(() => {
      handleSize();
    });

    resizeObserver.observe(ref.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [ref, handleSize]);

  const useIsomorphicLayoutEffect =
    typeof window !== "undefined" ? useLayoutEffect : useEffect;

  useIsomorphicLayoutEffect(() => {
    const timeout = setTimeout(() => {
      handleSize();
    }, 0);

    return () => {
      clearTimeout(timeout);
    };
  }, [ref, handleSize]);

  return [size];
}
