import envVariables from "src/lib/envVariables";
import reportErrorSentry from "src/lib/reportErrorSentry";
import { isHybridApp } from "src/lib/platform";

export const getContinueWithTranscarentTargetUrl = (): string | undefined => {
  const transcarentUrl: string | undefined = envVariables.SSO_LINK_TRANSCARENT;

  const transcarentDynamicAppUrl: string | undefined =
    envVariables.TRANSCARENT_DYNAMIC_APP_URL;

  if (!transcarentUrl) {
    reportErrorSentry("No SSO link defined");
    return undefined;
  }

  if (!transcarentDynamicAppUrl) {
    reportErrorSentry("No dynamic transcarent app link defined");
    return undefined;
  }

  if (isHybridApp()) {
    return transcarentDynamicAppUrl;
  } else {
    return transcarentUrl;
  }
};
