/**
 * The Payer IDs.
 * Defines the payer of the subscription.
 * - Used in the metadata of the purchase items.
 *    - "partner.payer": "79a815d6-262c-46e4-a20d-d942b79f8fee"
 * CASH_PAY = user pays with credit card
 * OTHER = users insurance or employer pays
 */
export enum PayerId {
  CASH_PAY = "79a815d6-262c-46e4-a20d-d942b79f8fee",
  TRANSCARENT = "9c201a22-bd05-41c8-b885-ab17b1c73330"
}
