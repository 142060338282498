import translate from "src/lib/translate";
import { RawCreateParams, ZodIssueCode } from "zod";

type validationRecord = Partial<Record<ZodIssueCode, string> & RawCreateParams>;

export const FIRST_NAME_SCHEMA_MESSAGES: validationRecord = {
  required_error: translate("first_name_required"),
  invalid_type_error: translate("first_name_required")
};

export const LAST_NAME_SCHEMA_MESSAGES: validationRecord = {
  required_error: translate("last_name_required"),
  invalid_type_error: translate("last_name_required")
};

export const STREET_ADDRESS_SCHEMA_MESSAGES: validationRecord = {
  required_error: translate("street_address_required"),
  invalid_type_error: translate("street_address_required")
};

export const CITY_SCHEMA_MESSAGES: validationRecord = {
  required_error: translate("city_required"),
  invalid_type_error: translate("city_required")
};

export const SEX_SCHEMA_MESSAGES: validationRecord = {
  required_error: translate("sex_required"),
  invalid_type_error: translate("sex_required"),
  invalid_enum_value: translate("sex_required"),
  message: translate("sex_required")
};

export const ZIP_CODE_SCHEMA_MESSAGES: validationRecord = {
  required_error: translate("zip_required"),
  invalid_type_error: translate("zip_required")
};

export const STATE_SCHEMA_MESSAGES: validationRecord = {
  required_error: translate("state_required"),
  invalid_type_error: translate("state_required"),
  invalid_enum_value: translate("state_required"),
  message: translate("state_required")
};
