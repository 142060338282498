import type { PurchaseItemResponse } from "@9amhealth/openapi";
import { SubscriptionDetailsResponse, TaskResponse } from "@9amhealth/openapi";
import type { SanityImageAssetDocument } from "@sanity/client";
import { Cubit } from "blac";
import type { SanityResolvedDocument } from "src/api/SanityService";
import SanityService from "src/api/SanityService";
import { globalEvents } from "src/constants/globalEvents";
import { CDCDPP_PROGRAM_ITEM_SKU } from "src/constants/misc";
import { logSentryBreadcrumb } from "src/lib/addSentryBreadcrumb";
import leftPadNumber from "src/lib/leftPadNumber";
import reportErrorSentry from "src/lib/reportErrorSentry";
import sanityQuery from "src/lib/sanityQuery";
import type {
    AppTaskDetails,
    TaskResponseKnown
} from "src/state/TaskManagementBloc/TaskManagementBloc";
import { ProfileProgramMembership } from "src/state/UserCubit/UserCubit";
import {
    subscriptionState,
    taskManagementState,
    userState
} from "src/state/state";
import type { TranslationKey } from "src/types/translationKey";

export type SelectModuleOption = "next-available" | "next-date";

interface ProgramModuleRelatedData {
  key?: string | "videoId";
  value?: string;
  _key: string;
}

export interface ProgramModuleItem {
  title?: string;
  status?: TaskResponse.status;
  moduleNumber?: string;
  description?: string;
  taskReference?: string;
  image?: SanityImageAssetDocument;
  relatedData?: ProgramModuleRelatedData[];
  tasks?: TaskResponseKnown[];
  _key: string;
}

export interface ProgramContent {
  // title, description, slug, nextVideoTitle, image
  title?: string;
  description?: string;
  detailText?: string;
  slug?: string;
  image?: SanityImageAssetDocument;
  attachments?: {
    file?: SanityResolvedDocument;
    name?: string;
    description?: string;
    _key: string;
  }[];
  moduleItems?: ProgramModuleItem[];
}

interface ProgramBlocState {
  userHasNoPrograms: true | undefined;
  programs: Set<string>;
  autoProgramDetected?: KnownProgram;
  programContent: Record<string, ProgramContent | undefined>;
}

interface ProgramModuleItemSanityResponse {
  description?: string;
  image?: SanityImageAssetDocument;
  title?: string;
  relatedData?: ProgramModuleRelatedData[];
  taskReference?: string;
  _key: string;
}

interface ProgramContentSanityResponse {
  description?: string;
  title?: string;
  detailText?: string;
  language?: string;
  slug?: {
    current?: string;
  };
  image?: SanityImageAssetDocument;
  moduleItems?: ProgramModuleItemSanityResponse[];
  attachments?: {
    file?: SanityResolvedDocument;
    name?: string;
    description?: string;
    _key: string;
  }[];
}

export enum KnownProgram {
  CDC_DPP = "cdcdpp",
  WEIGHT_MANAGEMENT = "weight-management",
  GENERAL_ALL = "general-all",
  DIABETES_PREVENTION = "diabetes-prevention",
  ONBOARDING = "onboarding",
  CARE = "care",
  MEAL_PLAN = "mealplan",
  LIFEBALANCE = "path-to-healthy-weight",
  BEHAVIORAL_HEALTH_SCREENING = "bh-screening"
}

export const KnownProgramInfo: Record<
  KnownProgram,
  {
    title: TranslationKey;
    description: TranslationKey;
  } | null
> = {
  "general-all": {
    title: `program.title_${ProfileProgramMembership.ALLINONE_HEALTHCARE_CONCIERGE}`,
    description: "program_allInOneConcierge_description"
  },
  cdcdpp: {
    title: `program.title_${ProfileProgramMembership.CDCDPP}`,
    description: `program.description_${ProfileProgramMembership.CDCDPP}`
  },
  "weight-management": {
    title: `program.title_${ProfileProgramMembership.HEALTHY_WEIGHT_JOURNEY}`,
    description: "program_healthyWeight_description"
  },
  "diabetes-prevention": {
    title: `program.title_${ProfileProgramMembership.DIABETES_AND_HEART_DISEASE_PREVENTION}`,
    description: `program_diabetesPrevention_description`
  },
  onboarding: null,
  care: null,
  mealplan: null,
  "bh-screening": null,
  [KnownProgram.LIFEBALANCE]: null
};

export const ITEM_SKU_PROGRAM_MAP: Record<string, KnownProgram | undefined> = {
  [CDCDPP_PROGRAM_ITEM_SKU]: KnownProgram.CDC_DPP
};

export default class ProgramBloc extends Cubit<ProgramBlocState> {
  constructor() {
    super({
      userHasNoPrograms: undefined,
      programs: new Set(),
      programContent: {}
    });

    window.addEventListener(globalEvents.USER_CLEAR, () => {
      this.emit({
        userHasNoPrograms: undefined,
        programs: new Set(),
        programContent: {}
      });
    });
  }

  updatePrograms = async ({
    loadSubscriptions
  }: {
    loadSubscriptions?: boolean;
  } = {}): Promise<void> => {
    if (loadSubscriptions) {
      await subscriptionState.loadAllSubscriptions();
    }

    // get all active subscriptions
    const activeActiveSubscriptions = subscriptionState.filterAllSubscriptions({
      status: [SubscriptionDetailsResponse.status.ACTIVE],
      looseCheck: true
    });

    // get all purchase items from active subscriptions
    const activePurchaseItems = activeActiveSubscriptions.reduce<
      PurchaseItemResponse[]
    >((acc, subscription) => {
      return [...acc, ...subscription.purchaseItems];
    }, []);

    // check which programs are active by checking the purchase items
    const activePrograms = activePurchaseItems.reduce<Set<string>>(
      (acc, purchaseItem) => {
        const program = ITEM_SKU_PROGRAM_MAP[purchaseItem.sku];
        if (program) {
          acc.add(program);
        }

        return acc;
      },
      new Set()
    );

    // update state
    this.emit({
      ...this.state,
      programs: activePrograms
    });
  };

  static parseProgramContentFormSanityResponse = async (
    responseContent?: ProgramContentSanityResponse
  ): Promise<ProgramContent> => {
    if (!responseContent) {
      throw new Error("No response content", {
        cause: "ProgramBloc.parseProgramContentFormSanityResponse"
      });
    }
    return {
      ...responseContent,
      slug: responseContent.slug?.current ?? "",
      moduleItems: responseContent.moduleItems?.map((moduleItem, index) => {
        const paddedIndex = leftPadNumber(
          index + 1,
          `${responseContent.moduleItems?.length}`.length
        );
        return {
          ...moduleItem,
          moduleNumber: paddedIndex
        } satisfies ProgramModuleItem;
      })
    };
  };

  static loadProgramMediaContent = async (
    program: KnownProgram
  ): Promise<ProgramContent | undefined> => {
    const sanityData = await SanityService.fetchSanityData(
      sanityQuery.programContentBySlug(program)
    );
    const responseContent = sanityData[0] as
      | ProgramContentSanityResponse
      | undefined;

    const data =
      ProgramBloc.parseProgramContentFormSanityResponse(responseContent);
    return data;
  };

  loadProgramTasks = async (
    program: KnownProgram
  ): Promise<TaskResponseKnown[] | undefined> => {
    try {
      return await taskManagementState.loadProgramTasks(program);
    } catch (error) {
      reportErrorSentry(error);
    }
  };

  addTasksToProgramContent = (
    content: ProgramContent | undefined,
    tasks: TaskResponseKnown[]
  ): ProgramContent | undefined => {
    if (!content) return;

    const moduleItems: ProgramModuleItem[] | undefined =
      content.moduleItems?.map((moduleItem) => {
        const moduleItemTasks = tasks.filter(
          (task) => task.group === moduleItem.taskReference
        );

        let moduleStatus: TaskResponse.status | undefined;

        if (
          // if all tasks are completed, set module status to completed
          moduleItemTasks.every(
            (task) => task.status === TaskResponse.status.COMPLETED
          )
        ) {
          moduleStatus = TaskResponse.status.COMPLETED;
        } else if (
          // if all are LOCKED, set module status to LOCKED
          moduleItemTasks.every(
            (task) => task.status === TaskResponse.status.LOCKED
          )
        ) {
          moduleStatus = TaskResponse.status.LOCKED;
        } else if (
          // if any of the tasks are IN_PROGRESS or AVAILABLE, set module status to IN_PROGRESS
          moduleItemTasks.some(
            (task) =>
              task.status === TaskResponse.status.IN_PROGRESS ||
              task.status === TaskResponse.status.AVAILABLE
          )
        ) {
          moduleStatus = TaskResponse.status.IN_PROGRESS;
        } else if (
          // if any tasks are SKIPPED, set module status to SKIPPED
          moduleItemTasks.some(
            (task) => task.status === TaskResponse.status.SKIPPED
          )
        ) {
          moduleStatus = TaskResponse.status.SKIPPED;
        }

        return {
          ...moduleItem,
          tasks: moduleItemTasks,
          status: moduleStatus
        } satisfies ProgramModuleItem;
      });

    return {
      ...content,
      moduleItems
    };
  };

  loadingPrograms: Record<string, boolean> = {};
  loadProgramContent = async (
    program: KnownProgram,
    forceUpdate?: boolean
  ): Promise<void> => {
    try {
      if (
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        (!forceUpdate && this.state.programContent[program]) ||
        this.loadingPrograms[program]
      ) {
        return;
      }

      this.loadingPrograms[program] = true;

      const responses = await Promise.all([
        ProgramBloc.loadProgramMediaContent(program),
        this.loadProgramTasks(program)
      ]);

      let content = responses[0];
      const tasks = responses[1];

      if (tasks) {
        content = this.addTasksToProgramContent(content, tasks);
      }

      this.loadingPrograms[program] = false;

      // set state
      this.emit({
        ...this.state,
        programContent: {
          ...this.state.programContent,
          [program]: content
        }
      });
    } catch (error) {
      logSentryBreadcrumb("errorHandler", "error while fetching program content", error);
    }
  };

  getProgramContent = (program?: KnownProgram): ProgramContent => {
    const fallback = {
      attachments: [],
      moduleItems: []
    };

    if (!program) return fallback;

    const hasContent = this.state.programContent[program];
    if (!hasContent) {
      void this.loadProgramContent(program);
    }
    return this.state.programContent[program] ?? fallback;
  };

  validateProgramSlug = (slug?: string): KnownProgram | false => {
    if (!slug) return false;
    const program = Object.values(KnownProgram).find(
      (p) => (p as string) === slug
    );
    return program ? program : false;
  };

  /**
   * select module item by status and/or taskReference
   * - first match is returned
   * - multiple selects work as "or"
   * - all options in one select work as "and"
   */
  selectProgramModule = (
    program?: KnownProgram,
    select: {
      status?: TaskResponse.status;
      taskReference?: string;
    }[] = []
  ): ProgramModuleItem | undefined => {
    const programModules = this.getProgramContent(program).moduleItems;
    if (!programModules) return;

    for (const moduleItem of programModules) {
      for (const selectItem of select) {
        let passedPrevious = true;

        // check for status match
        if (selectItem.status) {
          passedPrevious = moduleItem.status === selectItem.status;
        }

        // check for taskReference match
        if (selectItem.taskReference && passedPrevious) {
          passedPrevious =
            moduleItem.taskReference === selectItem.taskReference;
        }

        if (passedPrevious) {
          return moduleItem;
        }
      }
    }
  };

  // select a task from a module item to represent the overall status/state of the module
  static getModuleTaskRepresentative = (
    module?: ProgramModuleItem
  ):
    | {
        task: TaskResponseKnown;
        details: AppTaskDetails;
        allTasks: {
          task: TaskResponseKnown;
          details: AppTaskDetails;
        }[];
      }
    | undefined => {
    if (!module) return;

    // get the last task in the module
    const selectedTask = module.tasks?.[module.tasks.length - 1];
    if (!selectedTask) return;

    const taskDetails = taskManagementState.getTaskDetails(selectedTask);

    return {
      task: selectedTask,
      details: taskDetails,
      allTasks:
        module.tasks?.map((task) => ({
          task,
          details: taskManagementState.getTaskDetails(task)
        })) ?? []
    };
  };
  getModuleTaskRepresentative = ProgramBloc.getModuleTaskRepresentative;

  // find first module item that has a task with status AVAILABLE
  findFirstModuleItemByTaskStatus = (
    program: KnownProgram,
    status: TaskResponse.status
  ): ProgramModuleItem | undefined => {
    const { moduleItems } = this.getProgramContent(program);
    if (!moduleItems) return;
    return moduleItems.find((item) => {
      let isAvailable = false;

      for (const task of item.tasks ?? []) {
        if (task.status === status) {
          isAvailable = true;
        }
      }

      return isAvailable;
    });
  };

  // count how many module items have been completed by user
  countModuleItemsByStatus = (
    program: KnownProgram,
    status: TaskResponse.status
  ): number => {
    const { moduleItems } = this.getProgramContent(program);
    if (!moduleItems) return 0;
    return moduleItems.reduce((acc, item) => {
      return item.status === status ? acc + 1 : acc;
    }, 0);
  };

  checkUserProfileProgramData = async (): Promise<void> => {
    const program =
      await userState.checkProfileAttributesCurrentProgramMembership();

    const programKeyToKnownProgramMap: Record<
      ProfileProgramMembership,
      KnownProgram | null
    > = {
      [ProfileProgramMembership.MEDICARE]: null,
      [ProfileProgramMembership.ALLINONE_HEALTHCARE_CONCIERGE]:
        KnownProgram.GENERAL_ALL,
      [ProfileProgramMembership.CDCDPP]: KnownProgram.CDC_DPP,
      [ProfileProgramMembership.HEALTHY_WEIGHT_JOURNEY]:
        KnownProgram.WEIGHT_MANAGEMENT,
      [ProfileProgramMembership.DIABETES_AND_HEART_DISEASE_PREVENTION]:
        KnownProgram.DIABETES_PREVENTION
    };

    const knownProgram = program
      ? programKeyToKnownProgramMap[program[0]]
      : null;

    const userHasNoPrograms = knownProgram ? undefined : true;

    this.emit({
      ...this.state,
      autoProgramDetected: knownProgram ?? undefined,
      userHasNoPrograms
    });

    if (knownProgram) {
      await this.loadProgramContent(knownProgram, true);
    }
  };
}
