import { TaskResponse, UpdateTaskRequest } from "@9amhealth/openapi";
import { Button } from "@9amhealth/shared";
import { useBloc as useBloc2 } from "@blac/react";
import styled from "@emotion/styled";
import NotificationSettingsBloc from "components/NotificationSettings/NotificationSettingsBloc";
import Translate from "components/Translate/Translate";
import type { FC } from "react";
import React, { useEffect, useId, useMemo } from "react";
import FileType from "src/constants/fileType";
import { BlueCheckMark } from "src/constants/icons";
import { ONBOARDING_QUESTIONNAIRE_INITIAL_LAB } from "src/constants/misc";
import { isHybridApp } from "src/lib/platform";
import translate from "src/lib/translate";
import OnboardingBloc from "src/state/OnboardingBloc/OnboardingBloc";
import { KnownProgram } from "src/state/ProgramBloc/ProgramBloc";
import { chatState, taskManagementState, useBloc } from "src/state/state";
import TaskManagementBloc, {
  TaskKey
} from "src/state/TaskManagementBloc/TaskManagementBloc";
import InDialog from "src/ui/components/InDialog/InDialog";
import useGoToOrBack from "src/ui/hooks/useGoToOrBack";
import BlockingLoadingOverlayController from "components/BlockingLoadingOverlay/BlockingLoadingOverlayController";
import { ChatMessageMetaType } from "components/Chat/ChatBloc";
import CustomQuestionnaire from "components/CustomQuestionnaire/CustomQuestionnaire";
import CustomSuspense from "components/CustomSuspense/CustomSuspense";
import DocumentUploader, {
  SingleFileConfig
} from "components/DocumentUploader/DocumentUploader";
import { CenterQuestionnaireContent } from "components/InAppQuestionnaire/InAppQuestionnaire";
import { AppQueryPopupsController } from "../AppQueryPopupsBloc";
import Track from "../../Track/Track";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--space-md);
  text-align: center;

  h1 {
    text-wrap: balance;
  }
`;

const UploadWrap = styled.div`
  padding: 1rem 0;
  h3 {
    text-align: center;
  }
`;

const InitialLabDialog: FC<{
  returnUrl: string;
}> = ({ returnUrl }) => {
  const navigate = useGoToOrBack();
  const [, { handleInitialLabQuestionnaireCompleted, isTaskStatusFinal }] =
    useBloc2(OnboardingBloc);

  const [, { togglePushNotifications, pushNotificationsEnabled }] = useBloc(
    NotificationSettingsBloc,
    {
      create: () => new NotificationSettingsBloc()
    }
  );
  const [, { getTask }] = useBloc(TaskManagementBloc);
  const questionnaireTask = getTask(TaskKey.INITIAL_LAB_QUESTIONNAIRE);
  const uploadTask = getTask(TaskKey.UPLOAD_LABS);
  const completeLabsTask = getTask(TaskKey.COMPLETE_INITIAL_LAB_ORDER);

  const dataAvailable = isTaskStatusFinal(completeLabsTask);
  const labOrderInProgress =
    completeLabsTask?.status === TaskResponse.status.IN_PROGRESS;
  const showLabsQuestionnaire = !isTaskStatusFinal(questionnaireTask);

  const showUploadLabs =
    !labOrderInProgress &&
    !dataAvailable &&
    !isTaskStatusFinal(uploadTask) &&
    !showLabsQuestionnaire;

  const showFinalSuccess = !showLabsQuestionnaire && !showUploadLabs;

  const onClose = () => {
    AppQueryPopupsController.closePopup();
    navigate(returnUrl, {
      multiBack: true,
      replace: true
    });
  };

  const onQuestionnaireSubmit = async (): Promise<boolean> => {
    void taskManagementState.loadProgramTasks(KnownProgram.ONBOARDING);
    return false;
  };

  const textContext = dataAvailable ? "recordsVerified" : "profileReviewed";
  const showPushNotificationButton = isHybridApp() && !pushNotificationsEnabled;

  const documentSessionId = useId();
  const [fileIds, setFileIds] = React.useState<Record<string, string>>({});

  const handleFileUploadChange = (files: Record<string, string>) => {
    const filesEmptyValuesRemoved = Object.fromEntries(
      Object.entries(files).filter(([, value]) => value)
    );
    setFileIds(filesEmptyValuesRemoved);
  };

  const documents = useMemo(() => {
    const docs: Record<string, SingleFileConfig> = {};

    // fill with fieldIds
    for (const key in fileIds) {
      if (!fileIds[key]) continue;
      docs[key] = {
        title: translate("uploadPage", { number: 1 })
      };
    }

    if (Object.keys(fileIds).length === 0) {
      docs[Date.now()] = { title: translate("uploadPage", { number: 1 }) };
    } else {
      docs[Date.now()] = {
        title: "",
        icon: "plus"
      };
    }

    // add file type and tags
    for (const key in docs) {
      docs[key].metaFileType = FileType.LAB_RESULT;
      docs[key].tags = [
        "lab-report",
        `page-create-time-${key}`,
        `document-upload-session:${documentSessionId}`
      ];
    }

    return docs;
  }, [fileIds]);

  const handleSave = async () => {
    const filesIdList = Object.values(fileIds);

    if (filesIdList.length === 0) {
      return;
    }

    const [loadingDone] = BlockingLoadingOverlayController.startLoading({
      bg: "transparent"
    });
    await chatState.sendMessageWithFallback({
      meta: {
        filesIdList,
        "message:type": ChatMessageMetaType.labReportUpload,
        "content-value:generated": true
      },
      content: "Uploaded lab report"
    });

    if (uploadTask) {
      await taskManagementState.updateTaskStatus(
        uploadTask,
        UpdateTaskRequest.status.COMPLETED
      );
    }
    setFileIds({});
    loadingDone();
  };

  useEffect(() => {
    setFileIds({});
  }, []);

  return (
    <InDialog
      popup
      title={translate("task.yourInitialLab")}
      returnUrl={returnUrl}
      onClose={onClose}
    >
      {showLabsQuestionnaire && (
        <CenterQuestionnaireContent>
          <CustomSuspense>
            <CustomQuestionnaire
              resetScrollOnEachStep
              id={ONBOARDING_QUESTIONNAIRE_INITIAL_LAB}
              useRouting
              onDataSent={(data) =>
                void handleInitialLabQuestionnaireCompleted(data)
              }
              onLastStepCompleted={() => void onQuestionnaireSubmit()}
            />
          </CustomSuspense>
          <Track event="Initial Labs Dialog Questionnaire Shown" />
        </CenterQuestionnaireContent>
      )}
      {showUploadLabs && (
        <UploadWrap>
          <h3 className="as-h4-large">
            <Translate msg="chat.selectPhoto" />
          </h3>
          <DocumentUploader
            display={{
              ratio: 0.8,
              showSelectedTitles: false,
              action: "delete"
            }}
            config={{
              fileTypes: [
                "image/jpeg",
                "image/png",
                "application/pdf",
                "image/jpg"
              ],
              files: documents
            }}
            onChange={handleFileUploadChange}
          />
          <Button
            center
            onPress={() => void handleSave()}
            isDisabled={Object.keys(fileIds).length === 0}
          >
            Continue
          </Button>
          <Track event="Initial Labs Dialog Upload Shown" />
        </UploadWrap>
      )}
      {showFinalSuccess && (
        <Container>
          <div style={{ position: "relative", display: "inline-block" }}>
            <img
              src="https://cdn.sanity.io/images/g38rxyoc/production/363592e8be393656ae36c2ae078cdcb795aaf5ae-512x430.png"
              alt="Clipboard with a checkmark"
            />
            <div style={{ position: "absolute", top: "12px", right: "75px" }}>
              {dataAvailable && <BlueCheckMark />}
            </div>
          </div>

          <h1 className="as-subhead2">
            <Translate
              msg="task.yourInitialLab.title"
              variables={{ context: textContext }}
            />
          </h1>
          <p className="as-little">
            <Translate
              msg="task.yourInitialLab.description"
              variables={{ context: textContext }}
            />
          </p>
          <Track
            event="Initial Labs Dialog Success Shown"
            data={{
              showPushNotificationButton
            }}
          />
          {showPushNotificationButton && (
            <Button
              style={{ marginTop: "0.5rem" }}
              theme="sunrise"
              onPress={() => togglePushNotifications(true)}
            >
              <Translate msg="notification.turnOn" />
            </Button>
          )}
        </Container>
      )}
    </InDialog>
  );
};

export default InitialLabDialog;
