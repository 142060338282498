import styled from "@emotion/styled";
import type { ZonedDateTime } from "@internationalized/date";
import type { FC, MouseEventHandler } from "react";
import React, { useEffect, useLayoutEffect } from "react";
import { DateWheelPicker } from "src/ui/components/DateWheelPicker/DateWheelPicker";

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease-in-out;
  opacity: 1;

  &[aria-hidden="true"] {
    pointer-events: none;
    opacity: 0;
  }
`;

export const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  box-shadow: 0 -5px 2rem rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem 0.5rem 0 0;
  overflow: hidden;
  transform: translateY(0%);
  padding-bottom: var(--ion-safe-area-bottom, 0px);
  opacity: 1;
  transition:
    transform 0.3s ease-in-out,
    opacity 0.3s ease-in-out;

  &[aria-hidden="true"] {
    pointer-events: none;
    transform: translateY(100%);
    opacity: 0;
  }
`;

export const Bar = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  justify-items: end;
  align-items: center;
  background: var(--color-cream-dark);
  border: 1px solid var(--color-gray-light);
  border-left: 0;
  border-right: 0;
  padding: 10px 20px;
`;

export const Button = styled.button`
  color: var(--color-charcoal);
  background: transparent;
  cursor: pointer;
  padding: 0.6rem;
  font-weight: 700;
  font-size: 14px;
`;

export const PickerFrame = styled.div`
  height: 240px;
  width: min(100vw, 488px);
  margin: 0 auto;
  overflow: hidden;
`;

interface Props {
  onFinish: () => void;
  onChange: (dateTime: ZonedDateTime) => void;
  title?: string;
  granularity?: "day" | "minute";
  initialDate?: ZonedDateTime;
}

/*
 * Component for displaying a time picker designed for mobile devices. Own implementation of ionic inline picker.
 */
export const TimePickerSheet: FC<Props> = ({
  onFinish,
  onChange,
  title,
  granularity = "minute",
  initialDate
}) => {
  useEffect(() => {
    // hide keyboard when time picker is opened
    const activeElement = document.activeElement as HTMLElement | undefined;
    activeElement?.blur();
  }, []);

  const [show, setShow] = React.useState<boolean>(false);

  useLayoutEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 0);
  }, []);

  const animateOut = () => {
    setShow(false);
    setTimeout(() => {
      onFinish();
    }, 300);
  };

  const closeBackgroundClick: MouseEventHandler<HTMLDivElement> = (event) => {
    if (event.target === event.currentTarget) {
      animateOut();
    }
  };

  return (
    <>
      <Overlay onClick={closeBackgroundClick} aria-hidden={!show} />
      <div>
        <Wrapper aria-hidden={!show}>
          <Bar>
            <div />

            {title}

            <div>
              <Button
                onClick={() => {
                  animateOut();
                }}
              >
                Done
              </Button>
            </div>
          </Bar>

          <PickerFrame>
            <DateWheelPicker
              granularity={granularity}
              initialDate={initialDate}
              onDateSelected={(value: ZonedDateTime) => {
                onChange(value);
              }}
            />
          </PickerFrame>
        </Wrapper>
      </div>
    </>
  );
};
