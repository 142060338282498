import { Button } from "@9amhealth/shared";
import type { FC } from "react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { IconTranscarent } from "src/constants/icons";
import { APP_CONTENT_WIDTH_WITHOUT_PADDING } from "src/constants/layout";
import { Divider } from "src/ui/styled/X";
import Translate from "../Translate/Translate";
import { LoginView } from "./LoginForm";
import { getContinueWithTranscarentTargetUrl } from "src/lib/getContinueWithTranscarentTargetUrl";
import { isHybridApp } from "src/lib/platform";

const LoginOptions: FC = () => {
  const navigate = useNavigate();

  const handleLoginButtonClick = () => {
    navigate(`?view=${LoginView.loginForm}`);
  };

  const handleTranscarentButtonClick = () => {
    const target = getContinueWithTranscarentTargetUrl();

    if (!target) {
      return;
    }

    window.open(target, isHybridApp() ? "_blank" : undefined);
  };

  return (
    <>
      {" "}
      <nine-heading
        style={{
          "--section-max-width": `${APP_CONTENT_WIDTH_WITHOUT_PADDING}px`
        }}
      >
        <h3 className="as-h4-large">
          <Translate msg={"login_title"} />
        </h3>
        <nine-spacer s="sm"></nine-spacer>
        <p className="m0 color-c-80">
          <Translate msg={"login.subtitle"} variables={{ context: "manage" }} />
        </p>
      </nine-heading>
      <nine-spacer s="xl"></nine-spacer>
      <nine-content>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Button theme="sunrise" onPress={() => handleLoginButtonClick()}>
            <Translate msg="signin" variables={{ context: "email" }} />
          </Button>

          <>
            <nine-spacer s="sm" />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                color: "var(--color-gray)"
              }}
            >
              {" "}
              <Divider style={{ flex: 1 }} />{" "}
              <span style={{ flex: "0.2", textAlign: "center" }}>
                {" "}
                <Translate msg="or" />{" "}
              </span>{" "}
              <Divider style={{ flex: 1 }} />
            </div>
            <nine-spacer s="sm" />
            <Button
              theme="charcoal"
              hideArrow={true}
              outline={true}
              icon={<IconTranscarent />}
              onPress={() => handleTranscarentButtonClick()}
            >
              <Translate msg="login" variables={{ context: "transcarent" }} />
            </Button>
          </>
        </div>
      </nine-content>
    </>
  );
};

export default LoginOptions;
