import {
  AutoForm,
  AutoFormAutocompleteField,
  AutoFormInputField,
  Button,
  useAutoFormControls
} from "@9amhealth/shared";
import styled from "@emotion/styled";
import { isValidPhoneNumber } from "libphonenumber-js";
import React, { FC, useEffect, useRef } from "react";
import { Item } from "react-stately";
import { formatPhoneNumberNational } from "src/lib/formatPhoneNumber";
import { getAvailableStates } from "src/lib/getAvailableStates";
import translate from "src/lib/translate";
import { patientPCPState } from "src/state/state";
import { z } from "zod";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1em;
  max-width: var(--section-max-width, 100%);
  margin: 1.6rem auto min(400px, 50vh);
  box-sizing: border-box;
`;
const Group = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1em;

  @media screen and (min-width: 600px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const PhoneNumberSchema = z.string().refine((value) => {
  if (!value) return true;

  const parsed = formatPhoneNumberNational(value);

  if (!parsed) return false;

  return isValidPhoneNumber(parsed, "US");
}, translate("invalid_phone_number"));

export const PcpFormSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  street: z.string(),
  aptSuite: z.string().optional().nullable(),
  city: z.string(),
  state: z.string(),
  zip: z.string().length(5, translate("error.zipLength")),
  npi: z
    .string()
    .length(10, translate("error.npiLength"))
    .optional()
    .nullable(),
  organization: z.string().optional().nullable(),
  phone: PhoneNumberSchema.optional().nullable(),
  fax: PhoneNumberSchema.optional().nullable(),
  email: z.string().email().optional().nullable()
});

const PrimaryCarePhysicianForm: FC<{
  onData: (data: z.infer<typeof PcpFormSchema>) => void;
  initialValue?: Partial<z.infer<typeof PcpFormSchema>>;
}> = (props) => {
  const currentPcp = patientPCPState.state.pcp;
  const autoFormControls = useAutoFormControls({
    schema: PcpFormSchema,
    onSubmit: (data) => {
      props.onData(data);
    },
    initialValue: {
      lastName: props.initialValue?.lastName ?? currentPcp?.provider.lastName,
      firstName:
        props.initialValue?.firstName ?? currentPcp?.provider.firstName,
      phone: formatPhoneNumberNational(
        props.initialValue?.phone ?? currentPcp?.provider.phone ?? ""
      ),
      fax: formatPhoneNumberNational(
        props.initialValue?.fax ?? currentPcp?.provider.fax ?? ""
      ),
      email: props.initialValue?.email ?? currentPcp?.provider.email,
      npi: props.initialValue?.npi ?? currentPcp?.provider.npi,
      organization:
        props.initialValue?.organization ?? currentPcp?.provider.organization,
      city:
        props.initialValue?.city ?? currentPcp?.provider.providerAddress?.city,
      state:
        props.initialValue?.state ??
        currentPcp?.provider.providerAddress?.state,
      zip: props.initialValue?.zip ?? currentPcp?.provider.providerAddress?.zip,
      aptSuite:
        props.initialValue?.aptSuite ??
        currentPcp?.provider.providerAddress?.aptSuite
    }
  });
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const availableStates = getAvailableStates();
  return (
    <div ref={ref} style={{ scrollMarginTop: "100vh" }}>
      <AutoForm {...autoFormControls.props}>
        <Wrap>
          <Group>
            <AutoFormInputField
              name="firstName"
              label={translate("firstName")}
              isRequired
            />
            <AutoFormInputField
              name="lastName"
              label={translate("lastName")}
              isRequired
            />
          </Group>
          <AutoFormInputField
            name="street"
            label={translate("street_address")}
            isRequired
          />
          <Group>
            <AutoFormInputField
              name="aptSuite"
              label={`${translate("aptSuite")} (${translate("optional")})`}
            />
            <AutoFormInputField
              name="city"
              label={translate("city")}
              isRequired
            />
          </Group>
          <Group>
            <AutoFormAutocompleteField
              defaultItems={availableStates}
              name="state"
              label={translate("state")}
              isRequired
            >
              {(item) => <Item key={item.id}>{item.label}</Item>}
            </AutoFormAutocompleteField>
            <AutoFormInputField
              style={{ width: "100%" }}
              name="zip"
              inputMode="numeric"
              mask="00000"
              label={translate("zip")}
              isRequired
            />
          </Group>
          <hr />
          <AutoFormInputField
            name="npi"
            label={`${translate("npi")} (${translate("optional")})`}
            mask={"0000000000"}
            inputMode="numeric"
            description={translate("npi_long")}
          />
          <AutoFormInputField
            name="organization"
            label={`${translate("organization")} (${translate("optional")})`}
          />
          <AutoFormInputField
            name="email"
            label={`${translate("email")} (${translate("optional")})`}
            inputMode="email"
          />
          <Group>
            <AutoFormInputField
              name="phone"
              label={`${translate("phoneNumber")} (${translate("optional")})`}
              mask={{
                mask: "(000) 000-0000",
                lazy: true
              }}
              inputMode="tel"
            />
            <AutoFormInputField
              name="fax"
              label={`${translate("fax")} (${translate("optional")})`}
              mask={{
                mask: "(000) 000-0000",
                lazy: true
              }}
              inputMode="tel"
            />
          </Group>
          <Button type="submit">Submit</Button>
        </Wrap>
      </AutoForm>
    </div>
  );
};

export default PrimaryCarePhysicianForm;
