import { Button } from "@9amhealth/shared";
import { NineHeading } from "@9amhealth/wcl/generated/react";
import { useBlocNext } from "@blac/react";
import React from "react";
import FileType from "src/constants/fileType";
import translate from "src/lib/translate";
import { AddPharmacyInsuranceDocuments } from "src/state/PatientPharmacyInsuranceBloc/PatientPharmacyInsuranceBloc";
import DocumentUploader from "src/ui/components/DocumentUploader/DocumentUploader";
import Translate from "src/ui/components/Translate/Translate";
import MedicalInsuranceFormBloc from "./MedicalInsuranceFormBloc";
import styled from "@emotion/styled";
import { APP_CONTENT_WIDTH } from "src/constants/layout";

const Wrap = styled.div`
  max-width: ${APP_CONTENT_WIDTH}px;
  margin: 0 auto;
`;

const MedicalInsuranceUploadPhoto = () => {
  const [
    { allImagesSelected },
    { handleFileUploadCardPhotoChange, handleSaveCardPhotos, cardPhotoFileIds }
  ] = useBlocNext(MedicalInsuranceFormBloc);

  return (
    <Wrap>
      <NineHeading>
        <h3 className="as-h4-large">
          <Translate msg="insurance.uploadPhoto" />
        </h3>
      </NineHeading>
      <nine-spacer s="lg"></nine-spacer>
      <DocumentUploader
        config={{
          fileTypes: ["image/jpeg", "image/png"],
          files: {
            [AddPharmacyInsuranceDocuments.FRONT]: {
              initialFileId: cardPhotoFileIds?.frontPhotoFileId,
              title: translate("update.frontSide"),
              tags: ["pharmacy-insurance-card-front"],
              namePrefix: "Pharmacy Insurance Card Front",
              metaFileType: FileType.PHARMACY_INSURANCE_CARD
            },
            [AddPharmacyInsuranceDocuments.BACK]: {
              initialFileId: cardPhotoFileIds?.backPhotoFileId,
              title: translate("update.backSide"),
              tags: ["pharmacy-insurance-card-back"],
              namePrefix: "Pharmacy Insurance Card Back",
              metaFileType: FileType.PHARMACY_INSURANCE_CARD
            }
          }
        }}
        onChange={handleFileUploadCardPhotoChange}
      />

      <div style={{ justifyContent: "center", display: "flex" }}>
        <Button isDisabled={!allImagesSelected} onPress={handleSaveCardPhotos}>
          <Translate msg="confirm" />
        </Button>
      </div>
    </Wrap>
  );
};

export default MedicalInsuranceUploadPhoto;
