import React from "react";
import useAutoFormField from "./useAutoFormField";
import {
  CheckboxGroup,
  CheckboxGroupProps
} from "molecule/checkbox/CheckboxGroup";

function AutoFormCheckboxGroupField(
  componentProps: React.ComponentProps<typeof CheckboxGroup> & { name: string }
) {
  const [autoFormFieldProps] = useAutoFormField<CheckboxGroupProps>(
    componentProps,
    "checkbox"
  );

  if (!autoFormFieldProps || !autoFormFieldProps.name) return null;

  return <CheckboxGroup {...componentProps} {...autoFormFieldProps} />;
}

export default AutoFormCheckboxGroupField;
