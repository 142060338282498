import styled from "@emotion/styled";
import React from "react";
import { FC } from "react";

const Styled = styled.div`
  label: WithKeyboardPadding;
  width: 100%;
  height: 100%;

      margin-bottom: var(--stored-keyboard-height, 28vh);
  @media (pointer: coarse) {
    &:has(input:focus),
    &:has(textarea:focus) {
      margin-bottom: var(--stored-keyboard-height, 28vh);
    }
  }
`;

const WithKeyboardPadding: FC<{ children: React.ReactNode }> = ({
  children
}) => {
  return <Styled>{children}</Styled>;
};

export default WithKeyboardPadding;
