import * as Sentry from "@sentry/react";
import reportErrorSentry from "./reportErrorSentry";

export type SentryBreadcrumbCategory =
  | "api"
  | "appView"
  | "auth"
  | "chat"
  | "avatar"
  | "errorHandler"
  | "tracker"
  | "websocket";

export const addSentryBreadcrumb = (
  category: SentryBreadcrumbCategory | string,
  message: string,
  level: Sentry.SeverityLevel = "info",
  data?: AnyObject
): void => {
  Sentry.addBreadcrumb({
    category,
    message,
    level,
    data
  });
};

export const logSentryBreadcrumb = (
  category: SentryBreadcrumbCategory | string,
  ...msg: unknown[]
): void => {
  let message: string;
  if (typeof msg === "string") {
    message = msg;
  } else {
    try {
      const joined = msg.map((m) => JSON.stringify(m)).join(", ");
      message = joined;
    } catch (e) {
      reportErrorSentry(e);
      message = "error while stringifying breadcrumb message";
    }
  }

  Sentry.addBreadcrumb({
    category,
    message,
    level: "info"
  });
};
